import { createI18n } from "vue3-i18n";
import en from "./lang/en";
import th from "./lang/th";

const cloneLangObj = JSON.parse(JSON.stringify(en));
function iter(obj: any) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] !== null && typeof obj[key] === "object") {
      iter(obj[key]);
      return;
    }
    if (typeof obj[key] === "string") {
      if (obj[key].length > 40) {
        obj[key] = "▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒ ▓ ▒";
      } else if (obj[key].length < 5) {
        obj[key] = "▒▓▒▓";
      } else {
        obj[key] = "▒▓▒▓▒▓▒";
      }
    }
  });
}
iter(cloneLangObj);

const messages = {
  en: en,
  th: th,
  false: cloneLangObj,
};

const i18n = createI18n({
  locale: "en",
  messages: messages,
});

export default i18n;
