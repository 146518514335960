
import { defineComponent } from "vue";
import Navbar from "@/components/Navbar.vue";
import helpers from "@/helpers/global";
const __default__ = defineComponent({
  name: "App",
  components: {
    Navbar,
  },
  created() {
    this.$store.dispatch("auth/setUserLogin");
  },
  watch: {
    isReady(value) {
      if (value === true) {
        setTimeout(() => {
          try {
            const app: any = document.getElementById("app");
            let style = app.firstChild
              ? app.firstChild.getAttribute("style")
              : "";
            document.body.setAttribute("style", style);
            setTimeout(() => {
              document.body.style.visibility = "visible";
            }, 600);
          } catch (e) {
            document.body.style.visibility = "visible";
          }
        }, 200);
      }
    },
  },
  computed: {
    isReady(): boolean {
      return this.$store.getters["isReady"];
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      const team = this.$store.getters["team/team"];
      const userRoute = this.$store.getters["routes/user"];
      // console.log(user.color, "user.color");
      // console.log(team, "team");
      if (user || team) {
        let color =
          (user ? user.color : defaultCompanyColor) || defaultCompanyColor;
        if (team && !team.personal) {
          color = team.color ? team.color : defaultCompanyColor;
        }
        const check = helpers.isLightColor(color);
        if (check) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "inherrit";
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.iconColor = "invert(1)";
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "#fff";
        }
        return color;
      } else if (userRoute && userRoute.color) {
        const check = helpers.isLightColor(userRoute.color);
        if (check) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "inherrit";
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.iconColor = "invert(1)";
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.fontColor = "#fff";
        }
        return userRoute.color;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.fontColor = "#fff";
      }
      return defaultCompanyColor;
    },
  },
  data() {
    return {
      fontColor: "",
      iconColor: "",
    };
  },
  mounted() {
    this.setLang();
  },
  methods: {
    setLang() {
      const lang = localStorage.getItem("lang");
      if (lang == "EN") {
        (this as any).$i18n.setLocale("en");
      } else if (lang == "TH") {
        (this as any).$i18n.setLocale("th");
      } else if (lang == "FALSE") {
        (this as any).$i18n.setLocale("false");
      }
      // switch (lang) {
      //   case "EN":
      //     (this as any).$i18n.setLocale("en");
      //     console.log("en");
      //     break;
      //   case "TN":
      //     (this as any).$i18n.setLocale("th");
      //     console.log("th");
      //     break;
      //   case "false":
      //     (this as any).$i18n.setLocale("false");
      //     console.log("false");
      //     break;
      //   default:
      //     (this as any).$i18n.setLocale("en");
      // }
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0762e14a": (_ctx.companyColor),
  "e3fd2320": (_ctx.fontColor),
  "d22c6ef4": (_ctx.iconColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__