import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import helpers from "@/helpers/global";

import { getDatabase, get, query as $query, ref } from "firebase/database";

interface ActionContext {
  commit?: any;
  dispatch?: any;
  rootState?: any;
}

interface RouteDetails {
  path: string;
  uuid: string;
}
export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    lastInsertId: "",
    data: null,
    timeSection: null,
    user: null,
    loaded: false,
    candiates: null,
    dataCandidate: null,
  },
  mutations: {
    loaded(state: { loaded: boolean }, loaded: boolean): any {
      state.loaded = loaded;
    },
    error(state: { error: string }, error: string): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    lastInsertId(state: { lastInsertId: string }, lastInsertId: string): any {
      state.lastInsertId = lastInsertId;
    },
    data(state: { data: RouteDetails }, data: RouteDetails): any {
      state.data = data;
    },
    timeSection(state: { timeSection: string }, timeSection: string): any {
      state.timeSection = timeSection;
    },
    user(state: { user: any }, user: { id: string }): any {
      state.user = user;
    },
    candidates(state: { candidates: any }, candidates: { id: string }): any {
      state.candidates = candidates;
    },
    dataCandidate(
      state: { dataCandidate: any },
      dataCandidate: { id: string }
    ): any {
      state.dataCandidate = dataCandidate;
    },
  },
  getters: {
    loaded(state: { loaded: boolean }): any {
      return state.loaded;
    },
    error(state: { error: string }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    lastInsertId(state: { lastInsertId: string }): any {
      return state.lastInsertId;
    },
    data(state: { data: RouteDetails }): any {
      return state.data;
    },
    timeSection(state: { timeSection: string }): any {
      return state.timeSection;
    },
    user(state: { user: any[] }): any {
      return state.user;
    },
    candidates(state: { candidates: any[] }): any {
      return state.candidates;
    },
    dataCandidate(state: { candidates: any[] }): any {
      return state.candidates;
    },
  },
  actions: {
    async delete(
      { commit }: ActionContext,
      routeDetails: RouteDetails
    ): Promise<any> {
      commit("loading", true);

      const db = getFirestore();
      const path = routeDetails.path;
      const uuid = routeDetails.uuid;

      // remove this doc and it's expired route in the future
      const docRef = collection(db, "routes");
      const q = query(
        docRef,
        where("path", "==", path),
        where("uuid", "==", uuid)
      );
      const querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const snapshot = querySnapshot.docs[i];
        deleteDoc(doc(db, "routes", snapshot.id));
      }

      commit("loading", true);
    },
    async create(
      { commit, dispatch }: ActionContext,
      routeDetails: RouteDetails
    ): Promise<any> {
      // console.log("routes.create()");
      commit("loading", true);
      // console.log(routeDetails, "routeDetails");

      const db = getFirestore();
      await dispatch("delete", routeDetails);

      let notFound = true;
      let id = "";
      do {
        const url = helpers.generateString();
        id = url;
        const docRef = doc(db, "routes", id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          await setDoc(doc(db, "routes", id), {
            ...routeDetails,
            created_at: helpers.now(),
          });
          commit("lastInsertId", id);
          notFound = false;
        }
      } while (notFound);

      commit("loading", false);
    },
    async getRoute(
      { commit, dispatch, rootState }: ActionContext,
      id: string
    ): Promise<any> {
      commit("loading", true);
      const db = getFirestore();
      const docRef = doc(db, "routes", id);
      const docSnap = await getDoc(docRef);

      let teamId;

      if (docSnap.exists()) {
        // console.log(docSnap, "docSnap");
        // console.log(docSnap.data(), "data");
        const data = docSnap.data();

        // firestore: assessment_template
        if (data.path == "/assessment-test/:id") {
          const q = query(
            collection(getFirestore(), "assessment_templates"),
            where("routeId", "==", id)
          );
          const querySnapshot = await getDocs(q);
          for (const i in querySnapshot.docs) {
            const $doc = querySnapshot.docs[i];
            const formTemplate = $doc.data();
            if (formTemplate && formTemplate.languages_required) {
              if (data.data) {
                data.data.languages_required = formTemplate.languages_required;
              }
            }
            teamId = formTemplate.team_id;
          }
        } else if (data.path == "/assessment-test/:id/:params") {
          const docRef = doc(
            getFirestore(),
            "assessment_templates",
            data.data.formId
          );
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const formTemplate = docSnap.data();
            if (formTemplate && formTemplate.languages_required) {
              if (data.data) {
                data.data.languages_required = formTemplate.languages_required;
              }
            }
            teamId = formTemplate.team_id;
          }
        }

        commit("data", data);
        const sections = data.data.sections || [];
        const timeValue = sections.length * 5;
        commit("timeSection", timeValue);
        const emailKey = helpers.filterPath(
          helpers.filterEmail(data.data.creator)
        );
        const db = getDatabase();
        const path = `users/${emailKey}`;
        const user = (await get($query(ref(db, path)))).val();

        // load team of this form and then logo and color
        // companyLogoUrl -> src/views/ConfirmCandidate.vue
        if (teamId) {
          await dispatch(
            "team/getTeam",
            { teamId: user.teamId },
            { root: true }
          );

          let hasTeam = false;
          let isPersonal = true;
          const team = rootState.team.team;
          if (team) {
            hasTeam = true;
            isPersonal = team.personal || false;
          }
          const useTeamLogo = hasTeam && !isPersonal;
          if (useTeamLogo) {
            if (team.color) {
              user.color = team.color;
            }
          }
        }

        if (user.companyLogoUrl) {
          await dispatch("user/applyCompanyLogoUrl", user, { root: true });
        }

        commit("user", user);

        const routeData = docSnap.data();
        // console.log(routeData, "routeData");
        let formid = "";
        if (routeData.data.is_started_test == false) {
          formid = routeData.uuid;
        } else {
          formid = routeData.data.formId;
        }
        const itemscandidates: any[] = [];
        if (formid) {
          const $db = getFirestore();
          const q = query(
            collection($db, "assessments"),
            where("form_id", "==", formid)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc: any) => {
            const data = doc.data();
            if (data.status == "COMPLETED") {
              const item = {
                candidateEmail: data.email,
              };
              itemscandidates.push(item);
            }
          });
          commit("candidates", itemscandidates);
        }
      } else {
        commit("error", `Not found route ${id}`);
      }
      commit("loading", false);
      commit("loaded", true);

      dispatch("forceReady", null, { root: true });
    },
  },
};
