import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.$t('lang_code') == 'th' ? '' : ''}`)
  }, [
    (_ctx.$route.meta.navbar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_navbar)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 2))
}