import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  query,
  getDocs,
  collection,
  where,
  limit,
} from "firebase/firestore";

import helpers from "@/helpers/global";
import _ from "lodash";

// Collection Name
const CN = "caches";

const FORCE_QUERY_OPENAPI = true;
const OPENAI_OPT_STREAM = true;

// const OPENAI_MODEL_DEFAULT = "gpt-4";
// const OPENAI_MODEL_DEFAULT = "gpt-3.5-turbo";
// const OPENAI_MODEL_DEFAULT = "gpt-4-turbo-preview";
const OPENAI_MODEL_DEFAULT = "gpt-4o";

const OPENAI_ENDPOINT = "https://api.openai.com/v1/chat/completions";

const DEFAULT_PARAMS = {
  model: OPENAI_MODEL_DEFAULT,

  temperature: 0.01,
  max_tokens: 1024,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  stream: OPENAI_OPT_STREAM,
};

// const PROMPT_ROLE_SYSTEM = "";

const PROMPT_JOB_ROLE = `
We'll be working together to create an A5 Role Description for a <%= TypeOfOrganization %> called <%= CompanyName %>.

A5 format — named as such because the entire role description fits on an A5 sheet of paper.

Here’s how it works:

1. Accountability
The A5 starts with a list of 3–5 accountabilities that the role should focus on. Being “accountable” literally means having the ability to account for something, which is why each accountability takes the form of an indicator — a Key Performance Indicator (KPI) if you will —  that the role should monitor and report on. Importantly, accountabilities are not transferable meaning the role owns the indicator or KPI. KPIs must be measurable and clearly about outcomes, not activities.

2. Responsibility
Next, we have the top-5 responsibilities leveraged in the role. “Responsible” means having the ability to respond to something, which is why each responsibility takes the form of a task (or group of tasks) that the role should undertake. These responsibilities should be connected to the above-mentioned accountabilities. But unlike their counterparts, responsibilities can be shared and delegated.

3. Authority
Each responsibility is associated with a level of authority that goes along with the role. There are (at least) four such levels: (1) decide and act, (2) inform and act, (3) consult and act, or, simply (4) act on request (when authority lies with another role). Authority will differ between responsibilities, but we’ll want to skew towards autonomy and empowerment whenever possible. Because that’s how agile organizations are built.

Note the focus on roles rather than positions or titles. A5s are designed on the premise that people fill many roles in their day-to-day and that we’d all benefit from making explicit not only what those roles are but, also, what each specific role actually entails.

Add a section that provides practical examples of how the following core values should be acted on (behaviors) in the role context and provide examples: <%= coreValues %>.

Add a section that outlines the next step in the career ladder for this role with clear items of what needs to happen before they can be considered for that role.

Here is an example of a Role Description for the Customer Success role at the Manager level for a technology startup called Happily.ai:

**Accountability:**
1. Customer Retention: Ensure high customer retention rates across all product lines.
KPI: Customer churn rate, Net Promoter Score (NPS)

2. Operational Efficiency: Oversee and optimize customer success operations for maximum efficiency.
KPI: Average resolution time, customer query backlog

3. Team Performance: Ensure the Customer Success team is engaged, trained, and achieving its KPIs.
KPI: Team engagement score, customer satisfaction rate per agent

**Responsibility:**
1. Strategic Planning: Develop and implement a comprehensive customer success strategy.
Authority: (1) Decide and act

2. Team Management: Lead, hire, and train the Customer Success team to ensure they provide excellent customer service.
Authority: (1) Decide and act

3. Process Oversight: Oversee the creation, implementation, and optimization of customer success processes.
Authority: (1) Decide and act

4. Cross-functional Collaboration: Collaborate with Sales, Product, and Marketing teams to ensure alignment and a unified customer experience.
Authority: (2) Inform and act

5. Reporting & Analysis: Analyze customer data to derive insights and provide regular reports to leadership on customer health and areas of improvement.
Authority: (2) Inform and act

**Core Values and Expected Behaviors:**
1. Courage to Fail: Pilot a new customer feedback program, understanding it may need iterations before it's perfect.
2. Initiative-making: Recognizing a recurring customer issue and proactively creating a plan to address it at its root.
3. Responsiveness: Ensuring the Customer Success team promptly addresses customer concerns, demonstrating the company’s commitment to its users.
4. Excellence: Striving for a world-class customer experience, ensuring every interaction adds value and strengthens the customer relationship.

**Career Progression:** The next step in the career ladder for the Head of Customer Success & Operations is typically the Chief Operations Officer or VP of Customer Experience.

To be considered for this role, the individual must demonstrate the following:
1. Successfully reducing customer churn and consistently improving NPS scores.
2. Building and managing an effective and efficient Customer Success team.
3. Demonstrated improvements in operational efficiency in customer success processes.
4. Strong collaboration with other departments, especially in driving a unified customer experience.

Note: This role description highlights the expectations from the Customer Success Manager at Happily.ai. This role intertwines customer experience with operational efficiency, ensuring customers remain satisfied and loyal to the product offerings. Through strategic planning, process oversight, and cross-functional collaboration, they advocate for the customer within the organization. This role embodies the core values through actionable initiatives, always striving for operational and customer service excellence.

Now, create an A5 Role Description for the <%= Role %> at the <%= Level %> level.
`;

const request = async function (params: { messages: any[] }) {
  const params_: any = { ...DEFAULT_PARAMS, ...params };

  const endpoint = OPENAI_ENDPOINT;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
    },
    body: JSON.stringify(params_),
  };

  const response = await fetch(endpoint, requestOptions);
  const data = await response.json();
  return data.choices[0].message.content;
};

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    result: null,
    title: "",
    subtitle: "",
    isTyping: false,
    locked: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    result(state: { result: string }, result: string): any {
      state.result = result;
    },
    title(state: { title: string }, title: string): any {
      state.title = title;
    },
    subtitle(state: { subtitle: string }, subtitle: string): any {
      state.subtitle = subtitle;
    },
    isTyping(state: { isTyping: boolean }, isTyping: boolean): any {
      state.isTyping = isTyping;
    },
    locked(state: { locked: boolean }, locked: boolean): any {
      state.locked = locked;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    result(state: { result: string }): any {
      return state.result;
    },
    title(state: { title: string }): any {
      return state.title;
    },
    subtitle(state: { subtitle: string }): any {
      return state.subtitle;
    },
    isTyping(state: { isTyping: boolean }): any {
      return state.isTyping;
    },
    locked(state: { locked: boolean }): any {
      return state.locked;
    },
  },
  actions: {
    async generate(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      {
        role,
        level,
        typeOfOrg,
        coreValues,
      }: {
        role: string;
        level: string;
        typeOfOrg: string;
        coreValues: string[];
      }
    ): Promise<any> {
      const credits = 1;

      const quotaData = rootState.quota.data;
      if (!quotaData.unlimited) {
        if (quotaData.quota_remaining < credits) {
          commit("error", "Not enough quota");
          return;
        }
      }

      commit("result", null);
      commit("loading", true);
      commit("locked", true);
      let $coreValues = "";
      if (coreValues.length) {
        if (coreValues.length > 1) {
          const _coreValues = _.clone(coreValues);
          const last = _coreValues.pop();
          $coreValues = _coreValues.join(", ") + " and " + last;
        } else {
          $coreValues = coreValues[0];
        }
      }

      const variables = {
        CompanyName: _.get(rootState, "user.user.companyName"),
        Role: role,
        Level: level,
        TypeOfOrganization: typeOfOrg,
        coreValues: $coreValues,
      };
      commit("title", `${role} ${level}`);
      commit("subtitle", coreValues.join(" • "));

      let result;
      let cacheId: any;
      let cacheDetails: any;
      let responseText: string;
      let error;

      const cachePrefix = "prompt_jobrolegenerator";
      const uuid =
        cachePrefix + "=" + new URLSearchParams(variables).toString();

      const fs = getFirestore();
      const q = query(collection(fs, CN), where("uuid", "==", uuid), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc: any) => {
        cacheId = String(doc.id);
        cacheDetails = doc.data();
        result = cacheDetails.data;
      });

      const writeCache = async (
        params: any,
        _responseText: string,
        result: string
      ) => {
        const idPrefix = `${cachePrefix}-`;
        let notFound = true;
        let id = "";
        do {
          id = idPrefix + helpers.generateString(32);
          const docRef = doc(fs, CN, id);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            cacheDetails = {
              scope: cachePrefix,
              params: params,
              responseText: _responseText,
              data: result,
              uuid: uuid,
              created_at: helpers.now(),
            };
            cacheId = id;
            notFound = false;
          }
        } while (notFound);

        if (cacheDetails) {
          setDoc(doc(fs, CN, cacheId), cacheDetails, {
            merge: true,
          });
        }
      };

      // use credit before use openai becauset it's stream
      const teamId = rootState.user.user.teamId;
      await dispatch(
        "quota/useCredits",
        { teamId, credits: credits, scope: cachePrefix, variables: variables },
        { root: true }
      );

      if (!cacheId || FORCE_QUERY_OPENAPI) {
        const params = {
          messages: [] as any,
        };
        const content = _.template(PROMPT_JOB_ROLE)(variables);
        if (content.length) {
          // params.messages.push({ role: "system", content: PROMPT_ROLE_SYSTEM });
          params.messages.push({ role: "user", content: content });
        }

        //*
        try {
          if (OPENAI_OPT_STREAM) {
            const params_: any = { ...DEFAULT_PARAMS, ...params };
            const endpoint = OPENAI_ENDPOINT;
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
              },
              body: JSON.stringify(params_),
            };
            fetch(endpoint, requestOptions)
              .then((response: any) => {
                const reader = response.body.getReader();
                let resonseText = "";
                commit("loading", false);
                commit("isTyping", true);
                reader.read().then(function pump({ done, value }: any) {
                  if (done) {
                    // Do something with last chunk of data then exit reader
                    writeCache(params, resonseText, resonseText);
                    commit("isTyping", false);
                    commit("locked", false);
                    return;
                  }
                  // Otherwise do something here to process current chunk
                  const text = new TextDecoder().decode(value);
                  const prefix = "data: ";
                  const pos = text.indexOf(prefix);
                  if (pos === 0) {
                    const lines = text.split("\n");
                    for (const i in lines) {
                      if (!lines[i]) continue;
                      if (lines[i].indexOf(prefix) === 0) {
                        const v = lines[i].substring(prefix.length);
                        if (v === "[DONE]") continue;
                        const value = JSON.parse(v);
                        if (_.isObject(value)) {
                          const content = _.get(
                            value,
                            "choices[0].delta.content"
                          );
                          // console.log(content, "content");
                          if (content) {
                            resonseText += String(content);
                          }
                          commit("result", resonseText);
                          // console.log(resonseText, "resonseText");
                        }
                      }
                    }
                  }
                  // Read some more, and call this function again
                  return reader.read().then(pump);
                });
              })
              .catch((e: unknown) => {
                error = true;
                if (e instanceof Error) {
                  commit("error", e.message);
                }
                console.error(e);
              });
          } else {
            responseText = await request(params);
            result = responseText;
            writeCache(params, responseText, result);
            commit("locked", false);
          }
          error = false;
        } catch (e: unknown) {
          error = true;
          if (e instanceof Error) {
            error && commit("error", e.message);
          }
          console.error(e);
        }
        //*/

        /*
        const responseText = `**Accountability:**
        1. Code Quality: Ensure high-quality code is produced across all projects.
        KPI: Code review feedback, number of bugs found in production
        
        2. Project Delivery: Ensure projects are delivered on time and within budget.
        KPI: Project completion rate, budget adherence
        
        3. Team Performance: Ensure the software engineering team is engaged, trained, and achieving its KPIs.
        KPI: Team engagement score, individual performance metrics
        
        **Responsibility:**
        1. Technical Leadership: Provide technical guidance and mentorship to the software engineering team.
        Authority: (1) Decide and act
        
        2. Code Review: Conduct regular code reviews to ensure high-quality code.
        Authority: (1) Decide and act
        
        3. Project Management: Oversee the planning, execution, and delivery of software projects.
        Authority: (1) Decide and act
        
        4. Cross-functional Collaboration: Collaborate with Product, Design, and QA teams to ensure alignment and a unified product development process.
        Authority: (2) Inform and act
        
        5. Reporting & Analysis: Analyze project data to derive insights and provide regular reports to leadership on project health and areas of improvement.
        Authority: (2) Inform and act
        
        **Core Values and Expected Behaviors:**
        1. Innovation: Implementing new technologies or methodologies to improve the software development process.
        2. Disruptive Thinking: Challenging the status quo and proposing new solutions to existing problems.
        3. Continuous Learning: Regularly updating technical skills and sharing knowledge with the team.
        
        **Career Progression:** The next step in the career ladder for the Lead Software Engineer is typically the Engineering Manager or Director of Engineering.
        
        To be considered for this role, the individual must demonstrate the following:
        1. Consistently delivering high-quality code and successful projects.
        2. Providing effective technical leadership and mentorship to the team.
        3. Demonstrated improvements in the software development process.
        4. Strong collaboration with other departments, especially in driving a unified product development process.
        
        Note: This role description highlights the expectations from the Lead Software Engineer at SARIYER BLD. This role intertwines technical expertise with project management, ensuring high-quality software is delivered on time and within budget. Through technical leadership, code review, and cross-functional collaboration, they advocate for best practices within the organization. This role embodies the core values through innovative and disruptive thinking, always striving for continuous learning and improvement.`;
        console.log(responseText, "responseText");
        //*/
      } else {
        commit("locked", false);
      }

      /*
      if (result) {
        commit("isTyping", true)
        const lines = result.split("\n");
        let text = "";
        for (const i in lines) {
          setTimeout(() => {
            text += lines[i] + "\n";
            commit("result", text);
            if (Number(i) + 1 == lines.length) {
              commit("isTyping", false);
            }
          }, Number(i) * 500);
        }
      }
      //*/

      await dispatch("quota/load", rootState.user.user, { root: true });

      commit("result", result);
      commit("loading", false);
    },
  },
};
