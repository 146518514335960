export default {
  namespaced: true,
  state: {
    error: null,
    roleOptions: [
      "Account Executive",
      "Account Manager",
      "Accountant",
      "Actuary",
      "Administrative Assistant",
      "Advertising Manager",
      "Aeronautical Engineer",
      "Affiliate Manager",
      "Agricultural Scientist",
      "Analyst (can be broken down: Data Analyst, Business Analyst, Systems Analyst, etc.)",
      "Animator",
      "Application Developer",
      "Architect",
      "Art Director",
      "Artificial Intelligence Engineer",
      "Assembly Technician",
      "Astronomer",
      "Auditor",
      "Biomedical Engineer",
      "Brand Manager",
      "Business Development",
      "Chef",
      "Chemical Engineer",
      "Civil Engineer",
      "Clinical Research Associate",
      "Communications Specialist",
      "Compliance Officer",
      "Content Creator",
      "Content Strategist",
      "Copywriter",
      "Customer Success",
      "Customer Support",
      "Data Scientist",
      "Database Administrator",
      "Dentist",
      "Design Engineer",
      "Digital Marketing Specialist",
      "Doctor (Various specializations: Pediatrician, Surgeon, etc.)",
      "E-commerce Specialist",
      "Enterprise Sales",
      "Electrical Engineer",
      "Event Coordinator",
      "Financial Advisor",
      "Financial Analyst",
      "Front-end Developer",
      "Geneticist",
      "Geologist",
      "Graphic Designer",
      "HR Specialist",
      "Industrial Engineer",
      "Information Security Analyst",
      "Interior Designer",
      "IT Support Specialist",
      "Journalist",
      "Lawyer",
      "Logistics Manager",
      "Machine Learning Engineer",
      "Marketing Manager",
      "Mechanical Engineer",
      "Medical Lab Technician",
      "Nurse",
      "Operations Manager",
      "Paralegal",
      "Pharmacist",
      "Photographer",
      "Physiotherapist",
      "Product Manager",
      "Project Manager",
      "Psychologist",
      "Public Relations Specialist",
      "QA Engineer",
      "Real Estate Agent",
      "Research Scientist",
      "Sales Engineer",
      "Sales",
      "SEO Specialist",
      "Social Media Manager",
      "Software Engineer",
      "Solution Architect",
      "Supply Chain Manager",
      "Systems Engineer",
      "Tax Consultant",
      "Teacher",
      "Technical Writer",
      "Tech Sales",
      "Telemarketer",
      "UX/UI Designer",
      "Veterinarian",
      "Videographer",
      "Virtual Assistant",
      "Web Developer",
      "Writer",
      "Zoologist",
    ],
    levelOptions: [
      "Intern",
      "Trainee",
      "Junior",
      "Associate",
      "Entry-Level",
      "Mid-Level",
      "Senior",
      "Lead",
      "Supervisor",
      "Manager",
      "Head",
      "Director",
      "Vice President (VP)",
      "Senior Vice President (SVP)",
      "Executive Vice President (EVP)",
      "Chief",
      "President",
      "Partner",
      "Principal",
      "Executive Director",
      "Managing Director",
      "Board Member",
      "Founder",
      "Owner",
      "Consultant",
      "Contractor",
      "Freelancer",
      "Specialist",
    ],
    typeOfOrgOptions: [
      "Technology Startup",
      "E-commerce Company",
      "Non-Profit Organization",
      "NGO (Non-Governmental Organization)",
      "Social Enterprise",
      "Manufacturing Company",
      "Retail Business",
      "Wholesale Business",
      "Service Provider",
      "Consulting Firm",
      "Financial Institution",
      "Educational Institution",
      "Healthcare Provider",
      "Law Firm",
      "Media Company",
      "Advertising Agency",
      "Real Estate Agency",
      "Construction Company",
      "Logistics Company",
      "Food and Beverage Business",
      "Hospitality Business (Hotels, Resorts, etc.)",
      "Travel and Tourism Agency",
      "Energy Company",
      "Pharmaceutical Company",
      "Biotechnology Firm",
      "Agricultural Business",
      "Research Institute",
      "Environmental Organization",
      "Fashion and Apparel Company",
      "Publishing House",
      "Government Agency/Department",
      "Public Sector Unit (PSU)",
      "Multinational Corporation (MNC)",
      "Franchise",
      "Cooperative",
      "Family-Owned Business",
      "Sole Proprietorship",
      "Partnership",
      "Limited Liability Company (LLC)",
      "Public Limited Company (PLC)",
      "Private Limited Company",
      "B Corporation",
      "Think Tank",
      "Trade Union",
      "Professional Association",
      "Sports Organization",
      "Arts and Culture Institution",
      "Religious Organization",
      "Trade and Export Development Agency",
      "Venture Capital Firm",
      "Private Equity Firm",
      "Hedge Fund",
      "Crowdfunding Platform",
      "Digital Media Platform",
      "E-sports Organization",
      "Gaming Company",
      "Subscription Service",
      "Mobile App Startup",
      "FinTech Company",
      "InsurTech Company",
      "HealthTech Company",
      "AgriTech Company",
      "EdTech Company",
      "RealTech Company",
    ],
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    roleOptions(state: { roleOptions: string[] }): string[] {
      return state.roleOptions;
    },
    levelOptions(state: { levelOptions: string[] }): string[] {
      return state.levelOptions;
    },
    typeOfOrgOptions(state: { typeOfOrgOptions: string[] }): string[] {
      return state.typeOfOrgOptions;
    },
  },
};
