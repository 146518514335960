const en = {
  lang_code: "en",
  f_thai: "ไทย",
  thai: "Thai",
  f_english: "english",
  English: "English",
  test: "test",
  Name: "Name",
  Purpose: "Purpose",
  Language: "Language",
  Add: "Add",
  Detail: "Detail",
  Overall: "Overall",
  Rate: "Rate",
  Yes: "Yes",
  No: "No",
  Generate: "Generate",
  Clear: "Clear",
  Copy_Text: "Copy Text",
  Error: "Error",
  min_remaining: "min remaining",
  Step_n: "Step {step}",
  Assessment: "Assessment",
  main: {
    dot: ".",
    Values_and_Traits: "Values & Traits",
    Values_Alignment: "Values Alignment",
    Culture_Profile: "Culture Profile",
    Acceptable_Behaviors: "Acceptable Behaviors",
    Work_Style_Identifier: "Work Style Identifier",
    Human_Skills: "Human Skills",
    Logic_Test: "Logic Test",
    Custom_Survey: "Custom Survey",
  },
  placeholder: {
    full_name: "Full name",
    email: "Email",
    email_email_com: "email@email.com",
    Work_email: "Work email",
    First_name: "First name",
    Last_name: "Last name",
    Company_name: "Company name",
    What_is_your: "What is your job title",
    The_number_of_hires: "The number of hires per year (estimate)?",
    What_level_are_you: "What level are you at with your culture development?",
    Coupon_code: "Coupon code",
    Code: "Code",
    Key: "Key",
    Enter_coupon_code: "Enter coupon code",
    Email_Address_start: "Email Address*",
    Search_by_name_or: "Search by name or email address",
    Assessment: "Assessment",
    Your_rating: "Your rating",
    Select: "Select",
    Search: "Search",
    Name_your_assessment: "Name your assessment",
    What_is_this_assessment_for: "What is this assessment for?",
    Number_of_employees: "Number of employees",
    Enter_your_email: "Enter your email",
    Enter_your_name: "Enter your name",
    Search_by_name: "Search by name",
    Search_by_name_or_email: "Search by name or email",
    Choose_Assessment: "Choose Assessment",
    Choose_Status: "Choose Status",
  },
  btn: {
    Continue: "Continue",
    Email: "Email",
    ADD: "ADD",
    Next: "Next",
    Skip: "Skip",
    Save: "Save",
    Edit: "Edit",
    Clone: "Clone",
    Duplicate: "Duplicate",
    Delete: "Delete",
    Invite: "Invite",
    Quick_Invite: "Quick Invite",
    Get_Started: "Get Started",
    Close: "Close",
    Previous: "Previous",
    Finish: "Finish",
    Submit: "Submit",
    Remove: "Remove",
    Leave: "Leave",
    Do_it_later: "Do it later",
    Copy: "Copy",
    Send: "Send",
    Re_send_Invit: "Re-send Invite",
    Clear_Results: "Clear Results",
    Cancel: "Cancel",
    Copy_link: "Copy link",
    Copy_key: "Copy key",
    Regenerate: "Regenerate",
    Close_Window: "Close Window",
  },
  optional: {
    Hiring_candidates: "Hiring",
    New_Hires: "Onboarding",
    Current_team_members: "Team-building",
    Invited: "Invited",
    Started: "Started",
    Incompleted: "Incomplete",
    Completed: "Completed",
    personal: "Personal",
    shared: "Shared",
  },
  login: {
    Please_confirm_who_you: "Please confirm who you are",
    I_have_read_and: "I have read and I accept the ",
    terms_of_use: "terms and conditions",
    Send_me_free_stuff:
      "Send me free stuff and occasional emails about MyCulture's services",
  },
  timeout: {
    No_time_remaining: "No time remaining",
    The_allocated_time_for_this_section:
      "The allocated time for this section has run out. Please continue to the next step.",
    Session_Time_Out: "Session Time Out",
    Please_proceed_to_the_next_section:
      "Please proceed to the next section of the assessment to complete your submission. Otherwise, you are at risk of losing your responses and may have to re-take the assessment.",
  },
  pincode: {
    Enter_your_PIN_code: "Enter your PIN-code sent to",
    Sign_in_using_a: "Sign in using a different account",
  },
  createAccount: {
    Get_started_with_your: "Get started with your free assessment.",
    Create_my_free_account: "Create my free account",
    Already_have_a: "Already have a MyCulture account?",
    Log_in_to: "Log in to MyCulture",
    // Log_in_to: "Log in to your account",
    Log_in: "Log In",
    Sign_up: "Sign up",
    Do_not_have: "Don’t have an account yet?",
    OR: "OR",
    Continue_with_Google: "Continue with Google",
    Continue_with_Microsoft: "Continue with Microsoft Account",
    By_signing_up: "By signing up, I’ve read the",
    Terms_and_Conditions: "Terms and Conditions",
    Tell_us_a_bit: "Tell us a bit about yourself and your company.",
    Give_your_assessments:
      "Give your assessments your company’s look and feel.",
    Upload_your_Logo: "Upload your Logo",
    Finish_sign_up: "Finish sign-up",
    more_than: "more than",
    // new ui
    Let_s_get_started_with:
      "Let’s get started with your assessment. Please enter necessary information in the provided fields.",
    Sign_in_with: "Sign in with:",
    Sign_up_to_My_culture: "Sign up to MyCulture",
    Create_My_Free_Account: "Create My Free Account",
    Enter_pin_code_send: "Enter pin-code sent to your email",
    Language: "Language",
    Choose_language: "Choose language",
    Name: "Name",
    Enter_your_first_name: "Enter your first name",
    Last_Name: "Last Name",
    Enter_your_last_name: "Enter your last name",
    Job_title: "Job title",
    Enter_your_job_title: "Enter your job title",
    Enter_your_code_color: "Enter your code color",
    Company_name: "Company name",
    Enter_company_name: "Enter company name",
    Number_of_employees: "Number of employees",
    Choose_quantity: "Choose quantity",
    Drag_drop_or: "Drag&drop or",
    choose_file: "choose file",
    to_upload: "to upload",
    to_uplChoose_brand_coloroad: "Choose brand color",
    Company_Values_n_Branding: "Company Values & Branding",
    Please_help_us_with_the_final_details:
      "You’re almost done. Please help us with the final details so that we can generate tailor-made assessments for you.",
    Enter_your_core_values_n_max:
      "Select or enter your core values ({max} max)",
  },
  myAccount: {
    Log_out: "Log out",
    My_Account: "My Account",
    Help_n_Support: "Help & Support",
    Need_Help: "Need Help?",
    Please_leave_us_a_message:
      "Please leave us a message and we’ll get back to you super fast.",
    Message: "Message",
    Write_your_message_here: "Write your message here",
    Upload_an_attachment: "Upload an attachment",
    Thank_you_for_submitting_your_messasge:
      "Thank you for submitting your messasge. Our team will be getting back to you within the next 3-48 hours.",
    My_Profile: "My Profile",
    My_Company: "My Company",
    Plan_and_Billing: "Plan & Billing",
    Team: "Team",
    Teams: "Teams",
    My_Team: "My Team",
    Team_Info: "Team Info",
    Team_Member: "Member",
    Team_Members: "Members ({total})",
    Personal: "Personal",
    Create_a_new_team: "Create a new team",
    Create_a_team: "Create a team",
    Edit_a_team: "Edit Team Name",
    Please_enter_a_name_that_is_unique_and_recognizable:
      "Please enter a name that is unique and recognizable",
    To_invite_members_to_a_team:
      "To invite members to a team, you need to create a team.",
    Team_name: "Team name",
    Copy_from_Personal_Account: "Copy from Personal Account",
    company_and_branding: "Company & Branding Info",
    all_assessments_and_responses: "All Assessments & Responses",
    Invite_Members: "Invite Members",
    role_admin: "Admin",
    role_member: "Member",
    Invite_a_Member: "Invite a Member",
    Add_a_member_to_your_team_by_email_address:
      "Add a member to your team by email address",
    Member_Email: "Member Email",
    You_do_not_have_permission_to_access_the_requested_page:
      "You do not have permission to access the requested page",
    Pending: "Pending",
    Refer_a_friend: "Refer a friend",
    API_Key: "API Key",
    API_Key_intro:
      "This is your unique API key. It's like a personal handshake between your apps and our software. Keep it safe!",
    API_Key_generating_warning:
      "Regenerating your API Key is irreversible and any integration using the key will no longer work. Are you sure you want to proceed?",
    First_Name: "First Name",
    Last_Name: "Last Name",
    Language: "Language",
    Company_Name: "Company Name",
    Logo: "Logo",
    Upload_your_Logo: "Upload your Logo",
    Brand_Color: "Brand Color",
    Brand_Assets: "Brand Assets",
    Unlimited: "Unlimited",
    __remaining: "",
    credit: "credit",
    credits: "credits",
    remaining: "remaining",
    this_month: "this month",
    Add_Credit: "Add Credits",
    Company_Info: "Company Info",
    Enter_your_company_name: "Enter your company name",
    Switch_to_Team: "Switch to Team",
    Switch_to_Personal: "Switch to Personal",
    Switch_to_Team_Variable: "Switch to {teamName}",
    You_have_been_removed_from_the_team: "You have been removed from the team.",
    refer_a_friend: {
      Special_offer: "Special offer",
      Refer_a_friend_and: "Refer a friend and you both earn +10 free credits!",
      When_a_friend_signs:
        "When a friend signs up for MyCulture and creates an assessment, you’ll both earn 10 free credits.",
      Your_unique_referral_link: "Your unique referral link",
      Email_your_invite: "Email your invite",
      we_do_not_recognise: "Sorry, we don't recognize this email address.",
      Successful_Referrals: "Successful Referrals",
      refer_a_friend_success: "Email {email} has been invited",
    },
    planAndBill: {
      Your_current_plan: "Your current plan",
      Free_Plan: "Free Plan",
      Unlimited_credits: "Unlimited credits",
      Unlimited_credits_used: "Unlimited credits used",
      You_are_used: "You've used",
      out_of: "out of",
      credits: "credits",
      credits_remaining: "credits remaining",
      this_mounth: "this month",
      A_credit_gets_used:
        "A credit gets used up when a respondent completes an assessment.",
      You_must_have_available:
        "You must have available credits to send out invitations.",
      All_plans_have_access_to_the_same_benefit:
        "All plans have access to the same benefit",
      Unlimited_assessment_variations: "Unlimited assessment variations",
      Access_to_all_available:
        "Access to all available sections (regularly updated)",
      Group_analytics: "Group analytics",
      Bulk_invites: "Bulk invites",
      Custom_branding: "Custom branding",
      Email_Support: "Email support",
      Continue: "Continue",
      Downgrade_to: "Downgrade to",
      Upgrade_to: "Upgrade to",
      Pay_as_you_go: "Pay as you go",
      Unlimited: "Unlimited",
      For_businesses_that_occasionally:
        "For businesses that hire occasionally and want flexibility.",
      A_one_time_purchase_to_get_unlimited_credits:
        "A one-time purchase to get unlimited credits towards assessments and the manager toolbox.",
      Pay_one_per_credit: " Pay THB 35 per credit.",
      Buy_credit: "Buy credits",
      Buy_credit_n: "Buy credits {price}",
      Cancel_Subscription: "Cancel Subscription",
      Minimum_purchase_10_credits: "Minimum purchase 10 credits",
      Limited_time_offer: "Limited time offer",
      Manage_your_plan: "Manage your plan",
      Annual_Subscriptionogo: "Annual Subscription",
      Monthly_Subscription: "Monthly Subscription",
      For_businesses_wiht_high: "For businesses with high-volume hiring needs.",
      // ---
      _49_per_month: " Pay $49 per month",
      for_unlimited_credits: "for unlimited credits.",
      Subscribe: "Subscribe",
      Change_to_Annual: "Change to Annual",
      Change_to_Monthly: "Change to Monthly",
      Select_the_annual_plan: "Select the annual plan for a 25% discount",
      PAY_AS_YOU_GO: "Pay As You Go",
      Available_credits_allow_you:
        "Buy credits send out additional assessment invites and generate templates from your toolbox.",
      Buy_10_for_S10: "Buy 10 for $10",
      Buy_20_for_S18: "Buy 20 for $18 (10% off)",
      Buy_30_for_S24: "Buy 30 for $24 (20% off",
      Buy_40_for_S28: "Buy 40 for $28 (30% off)",
      Buy_n_credits: "Buy {credits} credits",
      Buy_n_credits_discount: "Buy {credits} credits ({discount}% discount)",
      Price_per_credits: "{currency} {price} per credit",
      Price_with_currency: "{currency} {price}",
      Redeem: "Redeem",
      SUBSCRIPTION: "SUBSCRIPTION",
      Access_unlimited_credits_during:
        "Access unlimited credits during your subscription period. Cancel at any time to ensure your selected plan doesn’t renew.",
      Monthly_for_S49: "Monthly for $49",
      Billed_today_and_on: "Billed today and on the 6th of each mont",
      Annual_for_S441: "Annual for $441 (25% off)",
      Billed_today_and_next: "Billed today and next on Dec 6, 2023",
      Coupon: "Coupon",
      Redeem_Free_Credits: "Redeem Free Credits",
      Enter_your_coupon:
        "Enter your coupon code here. Want some free credits? Reach out to us at ",
      sales_happily_ai: "sales@happily.ai",
      Coupon_Code: "Coupon Code",
      We_re_sorry: "We’re sorry, this code is invalid.",
      credits_redeemed_1: "",
      credits_redeemed_2: "credits redeemed",
      You_have_successfully:
        "You have successfully redeemed credits using your coupon code.",
      Failed_redeem: "Failed redeem",
      credits_used: "credits used",
    },
    paymentInteruptPopup: {
      title: "Payments coming soon",
      body: "Thank you for your interest to purchase credits. It's coming soon. In the meantime, refer a friend to earn +10 free credits.",
      confirmButtonText: "Refer a friend",
      cancelButtonText: "Cancel",
    },
  },
  fourZeroFour: {
    Looks_like_something: "Looks like something went wrong",
    the_page_or: "the page or link you are looking for is not available!",
    Our_Website: "Our Website",
  },
  assessments: {
    My_Core_Values: "My Core Values",
    My_assessments: "My Assessments",
    My_respondents: "My Respondents",
    Create_new_assessment: "Create new assessment",
    Create_assessment: "Create assessment",
    New_Assessment: "New Assessment",
    Get_started: "Get started",
    by_creating_a_new: "by creating a new assessment",
    We_re_generating_your_first_assessment:
      "Sit back and relax. We’re generating your first assessment.",
    Our_AI_is_working_with_your_organization_type:
      "Our AI is working with your organization type and set of core values to set up an assessment tailor-made fo you.",
  },
  assessmentsDetails: {
    sections: "sections",
    Invite_Candidates: "Invite Candidates ",
    By_public_link: "By public link",
    Public_link: "Public link",
    By_Email: "By Email",
    Invite_in_Bulk: "Invite in Bulk",
    INVITE_MULTIPLE_CANDIDATES: "INVITE MULTIPLE CANDIDATES",
    by_Email: "by Email",
    emails_detected: " emails detected",
    Comma_separate_multiple: "Comma separate multiple email addresses",
    Advanced_Invite_Sender: "Advanced Invite Sender",
    ADVANCED_INVITE_SENDER: "ADVANCED INVITE SENDER",
    Email_Subject: "Email Subject",
    Email_Body: "Email Body",
    Email_Sender: "Email Sender",
    Recipient_s: "Recipient(s)",
    Tip_Paste_contact: "Tip: Paste contact info from a spreadsheet",
    First_Name: "First Name",
    Last_Name: "Last Name",
    Email: "Email",
    Send_Test_Email: "Send Test Email",
    Send_Invite: "Send Invite",
    The_email_will_include: "The email will include a header and footer",
    Scenario: "Scenario",
    Scenario_Alignment: "Scenario Alignment",
    Created_by: "Created by",
  },
  create: {
    Create_new_assessment: "Create new assessment",
    Edit_assessment: "Edit assessment",
    Untitled_assessment: "Untitled assessment",
    Next_Step: "Next Step",
    Next: "Next",
    Previous: "Previous",
    Your_Sections: "Your Sections",
    Add_a_Section: "Add a Section",
    SET_UP_REQUIRED: "SET UP REQUIRED",
    Culture_Specific: "Culture Specific",
    General: "General",
    STEP_1: "STEP 1",
    STEP_2: "STEP 2",
    STEP_3: "STEP 3",
    Default_Thank_You_Page: "Default Thank You Page",
    Custom_Page: "Custom Page",
    Name_Assessment: "Name Assessment",
    Add_Section: "Add Section",
    Remove: "Remove",
    Fill_necessary_info: "Fill necessary information of your new assessment",
    Select_Sections: "Select Sections",
    Your_Culture_Model: "Your Culture Model",
    Publish: "Publish",
    Review_and_Publish: "Review & Publish",
    Details: "Details",
    Selected: "Selected",
    Select: "Select",
    Values_and_Traits: "Values & Traits",
    Values_and_traits_influence:
      "Values and traits influence behavior. When our values are aligned, working together is easy and natural. And when our desired traits are aligned, we can exercise these values in daily work.",
    Values_Alignment: "Values Alignment",
    Values_Alignment_Description:
      "Values determine behavior. When personal values are aligned with company core values, work is easy and natural. This is foundational to understanding culture fit.",
    Culture_Profile: "Culture Profile",
    Organizational_culture_is_driven:
      "Organizational culture is driven by competing values. Identify and match culture profiles based on",
    Clan_Create_Collaborate_and: "Collaborate, Create, Compete, and Control",
    // ---
    across_six_dimensions_of: "across six dimensions of work.",
    Determine_culture_profile: "Determine culture profile fit.",
    Determine_values_traits: "Determine value & trait fit.",
    Determine_value_alignment: "Determine value alignment.",
    // ---
    Behaviors_are_considered_toxic:
      "Behaviors are considered toxic when they don’t align with what’s expected. Understand behaviors that are acceptable, discouraged, or not-tolerated and identify mismatches.",
    Determine_expected_behaviors_and:
      "Determine expected behaviors and consequences.",
    Acceptable_Behaviors: "Acceptable Behaviors",
    Work_Style_Identifier: "Work Style Identifier",
    Identify_a_candidate_work_style:
      "Identify a candidate’s work style based on the 3P framework.",
    Product_Process_or_People: "Product, Process, or People.",
    // ---
    Understanding_the_inclination_for:
      "Understanding the inclination for each work style provides insight into how work is completed and prioritized.",
    Setup_your_company_values:
      "Setup your company values and traits to identify the attributes that makes your workplace unique.",
    Setup_your_culture_profile:
      "Setup your culture profile by identifying your balance between competing values across 6 dimensions of work.",
    Setup_your_acceptable_behaviors:
      "Setup your acceptable behaviors by going through workplace scenarios and deciding what is acceptable and what’s not.",
    No_set_up_required:
      "No set up required to assess work styles according to the 3P framework.",
    Communication_Styles: "Communication Styles",
    Ready: "Ready",
    Set_up: "Set up",
    SECTIONS: "SECTIONS",
    Sections: "Sections",
    DURATION: "DURATION",
    Languages_required_to_support: "Languages required to support",
    Advanced_Options: "Advanced Options",
    Additional_configurations_to:
      "Additional configurations to apply to your assessment.",
    Extra_Time: "Extra Time",
    min: "min",
    Sharing_Results: "Auto-share Results with Candidate",
    Auto_send_assessment: "Auto-send assessment results to the respondent",
    Open_ended_Feedback: "Open-ended Feedback",
    Allow_Comments: "Allow Comments",
    Allow_respondents_to:
      "Allow respondents to share open-ended feedback after each section",
    Human_Skills: "Human Skills",
    Human_skills_soft_skills:
      "Human skills (soft skills) —written communication, empathy, situational awareness, and critical thinking are essential for developing successful relationships and sound reasoning and decision-making.",
    Evaluate_human_skills: "Evaluate human skills.",
    No_set_up_required_to_assess_written:
      "No set up required to assess written communication, empathy, situational awareness, and critical thinking.",
    Your_Core_Values: "Your Core Values",
    Define_your_organizational:
      "Define your organizational core values as parameters of your Culture Model. Each section will be set up and evaluated based on your values.",
    Quick_Select_or_Add: "Quick Select or Add New Core Values",
    Start_AI_Assisted_Setup: "Start AI-Assisted Setup",
    Proceed_with_Manual_Setup: "Proceed with Manual Setup",
    Pending_Review: "Pending Review",
    Generate: "Generating..",
    Redirect_respondents_to: "Redirect Link",
    Carefully_check_all: "Carefully check all information that your provide.",
    If_something_is_incorrect: "If something is incorrect go back and edit it.",
    Assessment_Permissions: "Team Access",
    TeamAccess: {
      personal: "<strong>Private:</strong> Do not allow anyone else to access",
      shared: "<strong>Public:</strong> Allow all team members to access",
    },
    Logic_Test: "Logic Test",
    Logical_reasoning_is_key_to_problem_solving:
      "Logical reasoning is key to problem-solving and decision-making. This test gauges an ability to decipher patterns, draw logical conclusions, and tackle complex problems.",
    Determine_logical_reasoning_proficiency:
      "Determine logical reasoning proficiency.",
    Full_Details: `The Logic Test encompasses various types of logical puzzles, including pattern recognition, logical deduction, and spatial reasoning challenges. Each question is designed to probe the candidate's ability to process information, draw conclusions, and apply logical thinking to different scenarios.

      Candidates will encounter a range of problems, from identifying patterns in data sequences to deducing outcomes based on given premises. The test also includes visual logic questions, adding a layer of spatial and visual-spatial reasoning, essential for roles demanding high-level analytical skills.
      
      This module not only assesses a candidate’s raw logical aptitude but also provides insights into their approach to complex problem-solving, a key skill in today’s dynamic work environment.`,
    Custom_Survey: "Custom Survey",
    Custom_Survey_Description: `Custom Survey allows you to create a tailored questionnaire for your organization. Use it to gather specific feedback from your employees or candidates.`,
    Custom_Survey_Description_Footer: `Design your custom survey to capture insights that matter most to your company.`,
  },
  setup: {
    Setup_Complete: "Setup Completed",
    Thank_you_for_setting: "Thank you for setting up the",
    You_can_now_close_this_window: "You can now close this window",
    _Use_the_same_setup: "- Use the same setup as a previous assessment -",
    Proceed_with_a_new_setup: "Proceed with a new setup",
    Resume_setup: "Resume setup",
    Use_the_same_setup_as_a_previous_assessment:
      "Use the same setup as a previous assessment",
    create_new_setup: "Proceed with a new setup",
    valueAndTraits: {
      Setup_your_Values_and_Traits: "Setup your Values & Traits",
      Setup_your_Values: "Setup your Values",
      Select_Core_Values: "Select Core Values",
      Identify_your_most_important_values:
        "Identify your most important values",
      Select_Permission_to_play_values: "Select Permission-to-play values",
      Identify_your_basic_ethical_values: "Identify your basic ethical values",
      Select_Non_essential_Values: "Select Non-essential Values",
      Identify_values_that_do_not_guide_behaviors_here:
        "Identify values that do not guide behaviors here",
      Review: "Review",
      Confirm_your_value_assignments: "Confirm your value assignments",
      Not_at_all_important: "Not at all important",
      Very_important: "Very important",
      Your_company_core_values:
        "Your company’s core values are unique to you, and they provide you with a competitive advantage. Traits are characteristics that make it possible for people to act and behave according to a set of values.",
      In_the_next_steps_do:
        "In the next steps, do your best to select the values and traits that are most important to your organization.",
      Your_company_s_core_values_are:
        "Your company’s core values are unique to you, and they provide you with a competitive advantage. Permission-to-play values are your ethical requirements. And your non-essential values make it clear what does and doesn’t guide decisions around here.",
      Remember_that_every_value:
        "Remember that every value you choose to embody comes at a cost.",
      Defining_your_values: "Defining your values",
      Select_and_manage_value:
        "Select and manage a set of values that you would like to have available to evaluate and candidates select from.",
      You_can_add_values: "You can add values to the list too.",
      Values_Library: "Values Library",
      Do_not_include: "Do not include",
      Add_Value: "Add Value",
      Add_a_value: "Add a value",
      Add_a_value_to_the:
        "Add a value to the library of values. Please check to ensure it’s not too similar to an existing value.",
      New_Value_English: "New Value (English)",
      New_Value_Thai: "New Value (Thai)",
      Enter_value_here: "Enter value here",
      Identifying_key_traits: "Identifying key traits",
      Select_and_manage_traits:
        "Select and manage a set of traits that you would like to have available to evaluate and candidates select from.",
      You_can_add_traits: "You can add traits to the list too.",
      Traits_Library: "Traits Library",
      Add_traits: "Add traits",
      Add_a_trait: "Add a trait",
      Add_a_trait_to:
        "Add a trait to the library of traits. Please check to make sure it’s not too similar to an existing trait.",
      New_Trait_English: "New Trait (English)",
      New_Trait_Thai: "New Trait (Thai)",
      Enter_trait_here: "Enter trait here",
      core_values: "Your Core Values",
      permission_to_play: "Your Permission-To-Play Values",
      non_essential: "Your Non-Essential Values",
      moderately_important: "Your Moderately Important values",
      Setup_Completed: "Setup Completed",
      CORE_VALUE: "CORE VALUE",
      PERMISSION_TO_PLAY: "PERMISSION-TO-PLAY",
      Thank_you_for_setting_up:
        "Thank you for setting up your values. You can now use it to assess values fit with your candidates.",
      What_are_your_core_values_that_set:
        "What are your <u>core values</u> that set you apart from others?",
      These_are_the_unique_values_that:
        "These are the unique values that define the organization and set it apart from others. If the company lost these values, it wouldn't be the same company anymore. <u>Select up to six.</u>",
      What_are_your_permission_to_play:
        "What are your <u>permission-to-play values</u>?",
      These_are_basic_ethical_values:
        "These are basic ethical values your company should have. They are necessary to uphold but not unique to the organization. <u>Select up to six.</u>",
      What_are_non_essential_values_that_do_not:
        "What are <u>non-essential values</u> that do not guide decisions and actions around here?",
      These_are_values_that_do_not_significantly:
        "These are values that do not significantly influence the actions and decisions within the organization. They might be values that sound good on paper, but in reality, do not guide the organization or its employees in a meaningful way. <u>Select at least one.</u>",
      Review_your_values_selection:
        "Review your values selection and importance ratings",
      Confirm_the_assignment_of_each_value:
        "Confirm the assignment of each value to each category that represent the level of importance that value has to your organizational culture. Drag-and-drop to make final adjustments.",
      Set_up_Values_n_Traits: "Set up Values & Traits",
      Set_up_Values_Alignment: "Set up Values Alignment",
      Identify_the_attributes:
        "Identify the attributes that create a high-performing culture by rating a selection of values and traits by importance",
      Identify_your_values:
        "Identify the core, permission-to-play, and non-essential values that guide your decisions and actions",
      Description: "Description",
      Details: "Details",
      Covered_Skills: "Covered Skills",
      This_test_is_relevant_for: "This test is relevant for",
      Brief_Description:
        "Values act as the compass that guide our actions and behavior. The Values assessment module scrutinizes how a candidate's personal values align with the company's core, non-essential, and permission-to-play values, setting the groundwork for efficient, harmonious collaboration.",
      Full_Details: `This comprehensive Values assessment module is designed to gauge the degree of alignment between a candidate's personal values and those that the company holds dear. It isn't about finding the "right" or "wrong" values - instead, the focus is on discovering compatibility and alignment. A match between a candidate's values and the company's values can pave the way for a more seamless integration into the team, smoother collaboration, and a higher likelihood of job satisfaction.


      This module uses a carefully curated list of values with descriptions to allow candidates to reveal their values. The list is designed to be non-leading, unbiased, and neutral to ensure authenticity in responses. The results from this module are then cross-referenced with the company's core values (those that are non-negotiable and intrinsic to the company's identity), permission-to-play values (those that employees must meet to be a part of the organization), and non-essential values (those that, while appreciated, are not mandatory for successful integration).
      
      The process is not about excluding candidates with different values, but about identifying those who would feel at home within the company’s culture and contribute to it positively. Ultimately, our Values module aims to predict how candidates will interact with the existing team, how they will handle decision-making, and how they will respond to company culture.
      `,
      Covered_Skills_Text: `- Identifying personal values
      - Self-reflection and introspection
      - Understanding the role of values in decision-making
      - Compatibility with company culture and team dynamics`,
      This_test_is_relevant_for_Text: `- C-Level
      - Business Leaders
      - HR Leaders
      - HR Managers`,
    },
    cultureProfile: {
      Setup_your_Culture_Profile: "Setup your Culture Profile",
      The_Happily_Culture_Profile:
        "The Culture Profile is adapted from the Competing Values Framework and used by over 10,000 companies worldwide.",
      Identify_the_culture_type:
        "Identify the culture type that should dominate 6 different aspects of your organization. Do you want more focus on collaboration, control, competition, or creativity?",
      Setup_Completed: "Setup Completed",
      Thank_you_for_setting_up:
        "Thank you for setting up the Culture Profile. You can now publish it as part of your assessment.",
      Rate: "Rate",
      Overall: "Overall",
      Description: "Description",
      Details: "Details",
      Covered_Skills: "Covered Skills",
      This_test_is_relevant_for: "This test is relevant for",
      Brief_Description:
        "Culture is the backbone of an organization, shaped by a matrix of competing values. The Culture Profile module helps you identify and match candidates with the ideal culture types across six critical work dimensions: Collaborate, Create, Compete, and Control.",
      Full_Details: `The Culture Profile assessment module offers a unique approach to understanding and aligning with your company's culture, using the proven Competing Values Framework adopted by over 10,000 companies globally. It isn't just about identifying a candidate's preferred workplace culture, but about assessing their compatibility with your organization's specific culture type. This can significantly enhance employee satisfaction, productivity, and retention.


      In this module, candidates navigate a series of thought-provoking scenarios and questions exploring six key aspects of work life. The responses help determine the candidate's inclination towards one of the four culture types: Collaborate (a culture emphasizing teamwork, participation, and consensus), Create (a culture encouraging innovation, flexibility, and uniqueness), Compete (a culture that values achievement, results, and winning), and Control (a culture that highlights efficiency, stability, and doing things right).
      
      Understanding how a candidate fits into these cultural quadrants can be transformative. It not only helps place the candidate in a position where they are more likely to thrive but also bolsters the overall harmony and productivity within your teams.
      `,
      Covered_Skills_Text: `- Adaptability to various work cultures
      - Understanding of organizational dynamics
      - Ability to prioritize preferences
      - Awareness of the interplay between personal style and organizational success`,
      This_test_is_relevant_for_Text: `- C-Level
      - Business Leaders
      - HR Leaders
      - HR Managers`,
    },
    acceptableBehaviors: {
      Setup_your_Acceptable_Behaviors: "Setup your Acceptable Behaviors",
      Identify_areas_of_misalignment:
        "Identify areas of misalignment in acceptable behaviors to eliminate toxic behaviors and align on expectations.",
      In_each_scenario:
        "In each scenario, select the level at which each behavior is acceptable or unacceptable as a baseline for comparison.",
      Review_the_acceptable_behaviors:
        "Review the acceptable behaviors that you would like to evaluate. Add or remove scenarios as needed. We recommend that you have no more than 15 scenarios in total.",
      Workplace_Scenarios: "Workplace Scenarios",
      Add_a_workplace_scenario: "Add a workplace scenario",
      Add_a_scenario_that:
        "Add a scenario that would be evaluated by your organization and the respondants as either acceptable, frowned upon, actively discouraged, reprimanded, or zero-tolerance.",
      New_Scenario_English: "New Scenario (English)",
      New_Scenario_Thai: "New Scenario (Thai)",
      Enter_scenario_here: "Enter scenario here",
      Setup_your_Values: "Setup your Acceptable Behaviors",
      Setup_Completed: "Setup Completed",
      Thank_you_for_setting_up:
        "Thank you for setting up your Acceptable Behaviors. You can now publish it as part of your assessment.",
      Add_Scenario: "Add Scenario",
      scenario: "scenario",
      Description: "Description",
      Details: "Details",
      Covered_Skills: "Covered Skills",
      This_test_is_relevant_for: "This test is relevant for",
      Brief_Description:
        "Behaviors can become disruptive when they don't align with the expected norms. The Acceptable Behaviors module is designed to define and understand behaviors that are encouraged, discouraged, or not tolerated, helping to identify potential mismatches.",
      Full_Details: `Understanding and maintaining acceptable behaviors is essential for a healthy and productive workplace. The Acceptable Behaviors assessment module is designed to shed light on these standards, helping both candidates and employers determine a suitable match.


      In this module, candidates are presented with a series of hypothetical scenarios that reflect real-life workplace situations. The responses to these scenarios help identify the candidate's behavioral inclinations, which are then compared to the company's established standards of acceptable, discouraged, and non-tolerated behaviors.
      
      This process enables us to detect potential mismatches early on and can significantly contribute to building a respectful, inclusive, and harmonious workplace. It also aids in mitigating the risk of toxic behaviors that can hinder productivity, affect team morale, and lead to higher employee turnover.
      
      In addition, it also sets clear expectations about what behaviors are expected within the company, allowing candidates to better understand the culture they may be joining. This clarity can significantly improve both the hiring process and the candidate's integration into their new role.`,
      Covered_Skills_Text: `- Alignment of personal behaviors with company norms
      - Comprehension of behavior's impact on the workplace
      - Adherence to behavior standards
      - Respect for organizational boundaries`,
      This_test_is_relevant_for_Text: `- C-Level
      - Business Leaders
      - HR Leaders
      - HR Managers
      - Team Managers`,
    },
    humanSkills: {
      Description: "Description",
      Details: "Details",
      Covered_Skills: "Covered Skills",
      This_test_is_relevant_for: "This test is relevant for",
      Brief_Description:
        "Human skills, or soft skills, play a vital role in fostering successful relationships and facilitating sound decision-making. This module focuses on evaluating key human skills such as written communication, empathy, situational awareness, and critical thinking.",
      Full_Details: `The Human Skills assessment module has been meticulously designed to probe into and evaluate the more subtle, yet critical, skills that make a candidate successful in any role. As the world of work evolves, the ability to connect, understand, and effectively respond to various situations is becoming increasingly important.


      In this module, candidates are faced with two scenarios that measure their proficiency in written communication. This is not merely about grammar and vocabulary, but also about clarity, conciseness, and the ability to convey complex ideas effectively.
      
      Empathy is evaluated by exploring the candidate's capacity to understand and share the feelings of others, which is crucial in fostering strong relationships within a team and with clients. Situational awareness is tested through the candidate's ability to perceive, understand, and respond to dynamic circumstances or events in the workplace.
      
      Lastly, we assess critical thinking - a skill that underpins effective problem-solving and decision-making. This is gauged by the candidate's ability to objectively analyze information, reason logically, and draw reliable conclusions.`,
      Covered_Skills_Text: `- Proficiency in written communication
- Capacity for empathy and understanding others
- Acute situational awareness and adaptability
- Sound critical thinking and decision-making abilities`,
      This_test_is_relevant_for_Text: `- C-Level
      - Business Leaders
      - HR Leaders
      - HR Managers
      - Team Managers`,
    },
    workStyleIdentifier: {
      Description: "Description",
      Details: "Details",
      Covered_Skills: "Covered Skills",
      This_test_is_relevant_for: "This test is relevant for",
      Brief_Description:
        "Every individual has a unique approach to work, characterized by the 3P framework: Product, Process, or People. The Work Style Identifier module helps uncover a candidate's dominant work style, providing valuable insights into how they complete and prioritize tasks.",
      Full_Details: `The Work Style Identifier assessment module is a powerful tool that delves into a candidate's approach to work. By applying the 3P framework, we can better understand what drives a candidate and how they naturally operate within a work environment.


      In the Product category, individuals are driven by tangible results and deliverables, focusing on the final outcome. In the Process category, people are more inclined towards systematic, step-by-step methods, and consistency in their work. Lastly, the People category includes individuals who prioritize relationships and teamwork, valuing the human element of work above all.
      
      The assessment unfolds through a series of workplace scenarios, asking the candidate to select their dominant preference in each situation. This method offers a real-life approach to understanding a candidate's work style, providing insights into how they might perform tasks, interact with others, and align with the company's workflow.
      
      Identifying a candidate's dominant work style not only helps predict their fit within the team but also empowers managers to allocate tasks more effectively, ultimately leading to improved team performance and job satisfaction.
      `,
      Covered_Skills_Text: `- Understanding one's work style preferences
- Aligning personal work style with scenarios
- Recognizing the impact of work styles on team dynamics
- Flexibility and adaptability in diverse work environments`,
      This_test_is_relevant_for_Text: `- C-Level
      - Business Leaders
      - HR Leaders
      - HR Managers
      - Team Managers`,
    },
    logicTest: {
      Description: "Description",
      Details: "Details",
      Brief_Description:
        "The Logic Test is a critical tool for evaluating a candidate's reasoning abilities. This module challenges candidates with puzzles and scenarios that require keen logical analysis, testing their ability to decipher complex patterns and solve problems effectively.",
      Full_Details: `The Logic Test encompasses various types of logical puzzles, including pattern recognition, logical deduction, and spatial reasoning challenges. Each question is designed to probe the candidate's ability to process information, draw conclusions, and apply logical thinking to different scenarios.

      Candidates will encounter a range of problems, from identifying patterns in data sequences to deducing outcomes based on given premises. The test also includes visual logic questions, adding a layer of spatial and visual-spatial reasoning, essential for roles demanding high-level analytical skills.
      
      This module not only assesses a candidate’s raw logical aptitude but also provides insights into their approach to complex problem-solving, a key skill in today’s dynamic work environment.`,
      Covered_Skills: "Covered Skills",
      Covered_Skills_Text: `- Advanced problem-solving and critical thinking
      - Pattern recognition and logical sequence analysis
      - Spatial and visual-spatial reasoning
      - Deductive reasoning and hypothesis testing
      - Analytical decision-making under time constraints`,
      This_test_is_relevant_for_Text: ``,
    },
    customSurvey: {
      Set_up_Custom_Survey: "Set up Custom Survey",
      Setup_your_Custom_Survey: "Setup your Custom Survey",
      Question_Block: "Question Block",
      Open_ended: "Open-ended",
      Multiple_Choice: "Multiple Choice",
      Multiple_Select: "Multiple Select",
      Add_your_own_set_of_questions:
        "Add your own set of questions that help you gather essential information & feedback",
      Title_of_Section: "Title of Section",
      Description_optional: "Description (optional)",
      Short_Description: "Short Description",
      Setup_Completed: "Setup Completed",
      Thank_you_for_setting_up:
        "Thank you for setting up a Custom Survey section as part of your assessment.",
      questionTypes: {
        "open-ended": "Open-ended",
        "multiple-choice": "Multiple Choice",
        "multiple-select": "Multiple Select",
      },
      What_makes_you_happiest_at_work: "What makes you happiest at work?",
      Type_your_answer_here: "Type your answer here...",
      Add_Option: "Add Option",
      Option_A: "Option A",
      Option_B: "Option B",
      Add_and_configure_questions: "Add and configure questions",
      Select_from_different_question_and_content_blocks_to_create_an_easy_to_understand_survey:
        "Select from different question and content blocks to create an easy to understand survey",
      Review_your_questions_for_your_custom_survey:
        "Review your questions for your custom survey",
      addMultipleChoiceErrorMsg:
        "Maximum number of choices reached for multiple choice.",
      addMultipleSelectErrorMsg:
        "Maximum number of choices reached for multiple select.",
      removeMultipleChoiceErrorMsg:
        "The number of options is lower for multiple choice.",
      removeMultipleSelectErrorMsg:
        "The number of options is lower for multiple choice.",
      Preview: "Preview",
      Description: "Description",
      Details: "Details",
      Brief_Description:
        "Custom Survey is a flexible tool that allows you to create tailored questionnaires specific to your organization's needs. This module enables you to gather precise feedback and insights from employees or candidates, helping you make informed decisions about cultural fit and organizational alignment.",
      Full_Details: `The Custom Survey module offers a unique opportunity to delve deeper into aspects of your company culture that standard assessments might not cover. You can design questions that address your organization's specific values, goals, and challenges, ensuring that you collect the most relevant and actionable data.

In this module, you have the freedom to craft a variety of question types, including multiple choice, rating scales, and open-ended responses. This versatility allows you to explore both quantitative and qualitative aspects of a candidate's or employee's perspective, providing a more comprehensive understanding of their potential fit within your organization.

By utilizing custom surveys, you can:
1. Address specific cultural nuances unique to your company
2. Gather insights on particular skills or attributes crucial to your organization
3. Assess alignment with your company's mission and long-term objectives
4. Identify potential areas for organizational improvement

Understanding the responses to your custom survey can be transformative. It not only helps in making more informed hiring decisions but also provides valuable insights for enhancing your overall organizational culture and employee experience.`,
      Covered_Skills: "Covered Skills",
      Covered_Skills_Text: `- Ability to provide targeted feedback
      - Self-awareness and reflection
      - Alignment with specific organizational values
      - Understanding of company-specific practices or methodologies`,
      This_test_is_relevant_for: "This test is relevant for",
      This_test_is_relevant_for_Text: `- All levels of employees
      - Job candidates
      - Department heads
      - HR professionals
      - Leadership teams`,
    },
  },
  question: {
    common: {
      move_on_step_n: "Moving on to Step {step}",
      Start: "Start",
      thank_you: "Thank you",
      thank_you_msg:
        "We appreciate you taking the time to complete the assessment.",
      go_to_main_page: "Go to Main Page",
    },
    valueAndTraits: {
      Values_and_Traits: "Values & Traits",
      Your_values_guide_your:
        "Your values guide your natural tendencies and preferences —we want to act consistently with our values. And traits explain how our values express themselves.",
      In_this_sectio_you_have: "In this section, you have",
      minutes: "minutes",
      to_identify_and_select_your:
        "to identify and select your underlying values and traits.",
      Select_the: "Select the",
      values_that_best_describe: "values most important to you",
      traits_that_best_describe: "traits that best describe you",
      Click_on_a_word_to_add:
        "Click on a word to add or remove it from your selection.",
      SELECT: "SELECT",
      MORE_ITEMS: "MORE ITEMS",
      Rated_very_important_by: "Rated very important by ",
      but_unselected_by: " but unselected by ",
      Values: "Values",
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button. Please ensure you have enough time to complete this step without interruptions. 
      Thank you good luck!`,
      intro:
        "Our personal values guide our natural tendencies and preferences — we want to act consistently with our values. Think about what motivates your decisions and actions.",
      intro_highlight: "Identify and select your underlying personal values.",
    },
    cultureProfile: {
      Culture_Profile: "Culture Profile",
      The_Happily_Culture_Profile:
        "The Culture Profile is adapted from the Competing Values Framework and used by over 10,000 companies worldwide.",
      Which_culture_type_should:
        "Which culture type should dominate? Do you want more focus on collaboration, control, competition, or creativity?",
      Takes: "Takes",
      minutes: "minutes",
      to_go_evaluate_your_ideal:
        "to go evaluate your ideal organization based on 6 different areas.",
      Assessing_each_aspect_divide:
        "Assessing each aspect, divide 100 points among four choices. Give a higher number of points to the choice that is most similar to the organization you want and less or no points to the alternative that is least similar to what you’re looking for.",
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button. Please ensure you have enough time to complete this step without interruptions. 
        Thank you and good luck!`,
      intro: `The Culture Profile is adapted from the Competing Values Framework and used by over 10,000 companies worldwide. Which culture type should dominate? Do you want more focus on collaboration, control, competition, or creativity? Takes 5 minutes to go evaluate your ideal organization based on 6 different areas.`,
      Which_best_desribes_your_ideal_organization:
        "Which best desribes your ideal organization?",
      Which_best_desribes_your_ideal_leadership:
        "Which best desribes your ideal leadership?",
      Which_best_desribes_the_ideal_role_managers_play:
        "Which best desribes the ideal role managers play?",
      Which_best_desribes_what_ideally_holds_the_organization_together:
        "Which best desribes what ideally holds the organization together?",
      Which_best_desribes_the_basis_of_your_decisions:
        "Which best desribes the basis of your decisions?",
      Which_best_desribes_what_success_looks_like:
        "Which best desribes what success looks like?",
      Which_best_desribes_the_basis_of_how_decisions_should_be_made:
        "Which best desribes the basis of how decisions should be made?",
    },
    acceptableBehaviors: {
      Acceptable_Behaviors: "Acceptable Behaviors",
      Evaluating_Acceptable_Behaviors: "Evaluating Acceptable Behaviors",
      In_the_workplace_it_is_not_uncommon:
        "In the workplace, it is not uncommon to come across undesirable behaviors. We may tolerate some more than others.",
      Please_provide_a_rating_for_each:
        "Please provide a rating for each scenario based on your preference and tolerance levels.",
      Acceptable: "Acceptable",
      This_behavior_is_acceptable: "This behavior is acceptable to me.",
      Frowned_Upon: "Frowned Upon",
      This_behavior_is_not_acceptable:
        "This behavior is not acceptable and I am visibly upset when it happens.",
      Actively_Discouraged: "Actively Discouraged",
      This_behavior_is_unacceptable_Discouraged:
        "This behavior is unacceptable. I will go out of my way to discourage it.",
      Reprimanded: "Reprimanded",
      This_behavior_is_unacceptable_Reprimanded:
        "This behavior is unacceptable. There should be repercussions.",
      Zero_Tolerance_Policy: "Zero Tolerance Policy",
      This_behavior_is_irreprehensible:
        "This behavior is irreprehensible, and no second chance should be given.",
      Scenario: "Scenario",
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button. Please ensure you have enough time to complete this step without interruptions. 
      Thank you and good luck!`,
      intro: `In the workplace, it is not uncommon to come across undesirable behaviors. We may tolerate some more than others. Takes 5 minutes Please provide a rating for each scenario based on your preference and tolerance levels.`,
    },
    workStyleIdentifier: {
      Work_Style_Identifier: "Work Style Identifier",
      identify_your_work_style:
        "Identify your work style based on the 3P framework: Product, Process, or People. Better understand how you complete and prioritize activities at work.",
      complete_each_workplace_scenario:
        "Complete each workplace scenario by selecting your primary action, priority, or preference.",
      Based_on_your_natural_tendencies:
        "Based on your natural tendencies, what is your closest response or action to this workplace scenario?",
      SCENARIO: "SCENARIO",
      Scenario: "Scenario",
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button. Please ensure you have enough time to complete this step without interruptions. 
      Thank you and good luck!`,
      intro: `Identify your work style based on the 3P framework: Product, Process, or People. Better understand how you complete and prioritize activities at work. Complete each workplace scenario by selecting your primary action, priority, or preference.`,
    },
    humanSkills: {
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button.Please ensure you have enough time to complete this step without interruptions. 
        Thank you for your participation and good luck on the first step!`,
      intro: `Showcase your communication ability, empathy, situational awareness, and critical thinking by answering the following open-ended questions to the best of your ability. Be analytical by sharing your thought process, and be specific by providing examples.`,
      Showcase_your_communication_ability:
        "Showcase your communication ability, empathy, situational awareness, and critical thinking by answering the following open-ended questions to ",
      The_best_of_your: "the best of your ability.",
      Be_analytical_by_sharing:
        "Be analytical by sharing your thought process, and be specific by providing examples.",
      You_will_have_ten_min:
        "You’ll have 10-min in total to respond to two questions.",
      Write_your_response: "Write your response here...",
      Your_answer: "Your answer",
      Write_your_answer_here: "Write your answer here",
    },
    logicTest: {
      Logic_Test: "Logic Test",
      intro:
        "This section evaluates your skills in pattern recognition, logical deduction, and problem-solving. Pay close attention to detail and think analytically. The test is timed, emphasizing both accuracy and efficiency, with 4 sections.",
      Section: "Section",
      Question: "Question",
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button. Please ensure you have enough time to complete this step without interruptions. 
        Thank you and good luck!`,
      question_n: "Question {n}",
      question_button_label: "{name} ({point} Points)",
      answer_n:
        "<span style='font-size: 12px; font-weight: 700; color: {color}'>Answer</span>: {n})",
      correct_answer_n:
        "<span style='font-size: 12px; font-weight: 700; color: {color}'>Correct Answer</span>: {n})",
      sum_score_each_section_n: "{sum_point} out of {max_point} ({percent}%)",
    },
    customSurveys: {
      Custom_Surveys: "Custom Survey",
      intro:
        "Showcase your communication ability, empathy, situational awareness, and critical thinking by answering the following Surveys questions. Be analytical by sharing your thought process.",
      get_started_message: `Please note that you have <b>{minute} minutes</b> to complete this step. The timer will start once you click the <b>"Start"</b> button. Please ensure you have enough time to complete this step without interruptions. 
      Thank you good luck!`,
      Set_up_Custom_Survey: "Set up Custom Survey",
      Setup_your_Custom_Survey: "Setup your Custom Survey",
      Question_Block: "Question Block",
      Add_your_own_set_of_questions:
        "Add your own set of questions that help you gather essential information & feedback",
      Title_of_Section: "Title of Section",
      Description_optional: "Description (optional)",
      Short_Description: "Short Description",
    },
  },
  assessmentTest: {
    Hello: "Hello",
    minutes_remaining_defore: "",
    minutes_remaining_after: "minutes remaining",
    Thank_you_for_taking:
      "Thank you for taking the time to complete this assessment with us at",
    Thank_you_for_taking_end: ".",
    A_few_things_you_should: "A few things you should know before you start:",
    Plan_to_take: "Plan to take ",
    minutes_to_complete_this:
      " minutes to complete this assessment uninterrupted.",
    It_is_in_your_best_interest:
      "It is in your best interest to answer truthfully.",
    There_is_no_right: "There is no right or wrong answer.",
    If_you_re_unsure: "If you’re unsure, trust your gut instinct.",
    Thank_you_for_taking_to_complete:
      "Thank you for taking the time to complete the",
    Thank_you_for_taking_to_complete_the_assessment:
      "Thank you for taking the time to complete the assessment",
    section_of_the_assessment: "section of the assessment.",
    You_ve_completed_this_section: "You’ve completed this section",
    You_ve_completed_all_section: "You’ve completed all section",
    You_have_completed_all_sections:
      "You have completed all sections of the assessment. You can now close this window.",
    Once_you_re_ready:
      "Once you’re ready, please continue to the next section.",
    Go_to_the_Next_Section: "Go to the Next Section",
    POINTS_REMAINING: "POINTS REMAINING",
    Optional: "Optional",
    Anyz_feedback_or_thoughts: "Any feedback or thoughts to share about ",
    values_and_traits_q: "values and traits?",
    culture_profile_q: "culture profile?",
    acceptable_behaviors_q: "acceptable behaviors?",
    work_style_identifier_q: "work style identifier?",
    human_skills_q: "human skills?",
    Describe_a_time_when_you:
      "Describe a time when you had to solve a complex problem at work. What was your thought process, and how did you arrive at a solution?",
    Imagine_you_are_working_on:
      "Imagine you are working on a team project with a tight deadline, and you discover a significant flaw in the project’s design. What steps do you take to address the issue, and how do you ensure the project stays on track?",
  },
  assessmentResults: {
    Pattern_Recognition: "Pattern Recognition",
    Logical_Reasoning: "Logical Reasoning",
    Data_Interpretation: "Data Interpretation",
    Problem_Resolving: "Problem Resolving",
    trend_analysis: "Trend Analysis",
    decision_making: "Decision Making",
    business_intelligence: "Business Intelligence",
    strategic_planning: "Strategic Planning",
    pattern_recognition_detail:
      "Ability to decipher and apply complex patterns, essential for recognizing trends, errors or anomalies in various data sets.",
    logical_reasoning_detail:
      "Ability to analyze scenarios and deduce logical outcomes, which is vital for diagnosing issues and making informed decisions in complex situations.",
    data_interpretation_detail:
      "Ability to interpret and draw conclusions from graphical data a skill crucial in many roles that require data analysis and strategic decision- making.",
    problem_pesolving_detail:
      "Ability to solve problems by applying logical reasoning Problem-solving skills are critical for efficiently tackling challenges and optimizing resources in the workplace.",
    A_logic_test_profile_for:
      "An overlay of candidate logic scores across four sections that test skills essential to solving problems and making effective decisions.",
    Overall: "Overall",
    Create: "Create",
    Compete: "Compete",
    Control: "Control",
    Collaborate: "Collaborate",
    people_oriented: "people-oriented",
    dynamic_entrepreneurial: "dynamic & entrepreneurial",
    results_oriented: "results-oriented",
    process_oriented: "process-oriented",
    Values: "Values",
    and_: " & ",
    Traits: "Traits",
    Culture_: "Culture",
    Profile_: "Profile",
    Acceptable_: "Acceptable",
    Behaviors_: "Behaviors",
    COPY_LINK: "COPY LINK",
    SEND_RESULTS_TO: "SEND RESULTS TO",
    RE_SEND_RESULTS_TO: "RE-SEND RESULTS TO",
    Completed_on: "Completed on",
    Score_Summary: "Score Summary",
    Values_alignment: "Values alignment",
    Dominant_Characteristics: "Dominant Characteristics",
    Organizational_Glue: "Organizational Glue",
    Organizational_Leadership: "Organizational Leadership",
    Strategic_Emphases: "Strategic Emphases",
    Management_of_Employees: "Management of Employees",
    Criteria_of_Success: "Criteria of Success",
    Step_desc: `Allocate 100 points across four types based on the likeness to your organization, giving more points to the types most similiar.`,
    Written_Communication: "Written Communication",
    Empathy: "Empathy",
    Situational_Awareness: "Situational Awareness",
    Critical_Thinking: "Critical Thinking",
    importance: "importance",
    Scenario: "Scenario",
    selection_of_the_candidate_values:
      "This is the selection of the candidate’s values, color-coded by level of importance based on the organization’s values.",
    Our_values_guide_our:
      "Our values guide our natural tendencies and how we work, act, and make decisions.",
    This_is_the_selection:
      "This is the selection of the candidate’s traits, color-coded by level of importance based on the organization’s ideal traits.",
    Our_traits_guide_give:
      "Our traits guide and give us the ability to behave, act, and deliver on our values.",
    The_overall_overlap_between:
      "The overall overlap between the candidate’s ideal work culture and your organization’s work culture.",
    Hover_over_each_dimension: "Hover over each dimension to see",
    how_each_value_is: "how each value is expressed in this section",
    A_comparison_between_what:
      "A comparison between what the candidate and organization feel are acceptable behaviors or not. For any mismatched pair, we recommend having a conversation to clarify expectations.",
    The_candidate_primary_work_style:
      "The candidate’s primary work style suggests natural work tendencies. It does not describe a limitation but explains how they prefer to work and work with others.",
    Work_Style__: "Work Style",
    Composition__: "Composition",
    Product: "Product",
    Doers_and_executors_They:
      "Doers and executors. They’re driven by getting things done and delivering results. However, they often overlook details and people’s sensitivities in their quest to accomplish work.",
    Process: "Process",
    Learners_and_thinkers_They:
      "Learners and thinkers. They are strongest in being analytical and thorough, knowing that success lies in the details. However, they often spend too much time perfecting work rather than getting things done in time or taking care of people.",
    People: "People",
    Relationship_builders_They:
      "Relationship builders. They understand people and know how to get the best out of them. However, they often spend too much time making others feel great rather than follow-up on details or getting work done.",
    Select_a_dimension_to: "Select a dimension to learn more",
    Tap_on_a_row_in_the:
      "Tap on a row in the left panel to see the alignment between the company and respondent's preferences",
    Group_Results: "Group Results",
    Analytics: "Analytics",
    Legend: "Chart Legend",
    The_overlap_between_the__workplace:
      "The overlap between the candidate’s ideal type of workplace and your organization’s type of workplace.",
    A_human_skills_profile_for:
      "A human skills profile for each candidate based on written communication, empathy, situational awareness, and critical thinking",
    There_are_no_values_assigned:
      "There are no values assigned to this level of importance.",
    Progress: "Progress",
    Assessment_Results_for: "Assessment Results for",
    Human_: "Human",
    Skills_: "Skills",
    The_candidate_human_skills_are:
      "The candidate’s human skills are evaluated based on how they responded to two open-ended questions. An evaluation of written communication, empathy, situational awareness, and critical thinking is provided —also how core values are expressed (core values ratings do not factor into the overall score but are displayed for alignment purposes).",
    Completion_Time: "Completion Time",
    Values_of: "$1’s Values",
    values_info: `This is the selection of the candidate’s chosen values color-coded based on how well they align with $1’s values.`,
    Analysis: "Analysis",
    analysis_result: `The candidate's values align strongly with the company's core and permission-to-play values, particularly in areas such as adaptability, entrepreneurial spirit, excellence, and openness, which can be advantageous for integration and contribution within the organization. However, there is a notable discrepancy regarding wealth; the candidate values it significantly while the organization considers it a non-essential value, indicating a potential conflict of interest or motivation. This discrepancy needs to be openly discussed and addressed to ensure the candidate's personal ambitions align with the company's objectives and overall mission.`,
    Company_Values: `This is a selection of $1’s values categorized based on their importance in guiding actions and decisions here.`,
    Core_Values: "Core Values",
    Core_Values_desc:
      "These are the unique values that define the organization and set it apart from others.",
    Permission_to_play_values: "Permission-to-play values",
    Permission_to_play_values_desc:
      "These are basic ethical values your company should have. They are necessary to uphold but not unique to the organization. ",
    Non_essential_values: "Non-essential values",
    Non_essential_values_desc:
      "These are values that do not significantly influence the actions and decisions within the organization.",
    Acceptable_Behaviors_Info: `A comparison between what the candidate and organization feel are acceptable behaviors or not. For any mismatched pair, we recommend having a conversation to clarify expectations.`,
  },
  coreValuesAnalyzer: {
    My_Core_Values: "My Core Values",
    Audit: "Audit",
    Skills: "Skills",
    Survey_Question: "Survey Questions",
    Microlearning: "Microlearning",
    Clarity: "Clarity",
    Practical: "Practical",
    Best_Practice: "Best Practice",
    Inspiring: "Inspiring",
    Audit_Summary: "Audit Summary",
    Purpose_driven: "Purpose-driven",
    Alternative_Options: "Alternative Options",
    Auditing_you_values: "Auditing your values…",
    Overall: "Overall",
    Simple_Language: "Simple Language",
    Potential_Misunderstanding: "Potential Misunderstanding",
    Consistency: "Consistency",
    Actionable: "Actionable",
    Easy_To_Reinforce: "Easy To Reinforce",
    Measureable: "Measureable",
    Not_Permission_To_Play: "Not Permission To Play",
    Sense_Of_Pride: "Sense Of Pride",
    Positive_Impact: "Positive Impact",
    Analyze_everything_you:
      "Analyze everything you need to know about your core values, and get recommendations on skills to develop, questions to ask, and information to share.",
    Enter_from_1_to: "Enter your core values (8 max)",
    Analyze: "Analyze",
    Analyzing: "Analyzing",
    What_you_get: "What you get",
    Audit_your_core_values: "Audit your core values",
    Analyze_how_well_your:
      "Analyze how well your core values score on clarity, being practical, following best practice, and being inspiring.",
    Ratings_recommendations_and:
      "Ratings, recommendations, and alternate core values are provided to help you develop your core values.",
    Get_a_Skills_Development: "Get a Skills Development Plan",
    Get_a_skill_evelopment:
      "Get a skill development framework that helps your employees can effectively express and deliver on your values.",
    Leaders_an_encourage_and:
      "Leaders can encourage and reward employees who embody these values, and provide opportunities for employees to practice and develop these skills.",
    Generate_Survey_Questions: "Generate Survey Questions",
    Help_your_employees_regularly:
      "Help your employees regularly reflect and share feedback based on your core values. Add these questions to your pulse survey or feedback tool of choice.",
    Learning_Content_to_Support: "Learning Content to Support Your Values",
    // --
    Get_micro_learning_content:
      "Get micro-learning content snippets to share through various channels such as emails, company intranet, social media, or a learning management system.",
    Short_and_easily_digestible:
      "Short and easily digestible, they allow employees to reflect on and improve upon the core values quickly and conveniently.",
    Skills_and_Development_Guidance: "Skills & Development Guidance",
    In_order_for_your:
      "In order for your employees to express and stay true to your core values they must possess or and continuously develop the following skills:",
    By_veloping_and_supporting:
      "By developing and supporting these skills, employees can effectively express and deliver on the core values of",
    Leaders_can_encourage_and:
      "Leaders can encourage and reward employees who embody these values, and provide opportunities for employees to practice and develop these skills.",
    Questions: "Questions",
    Regularly_get_your_employees:
      "Regularly get your employees to reflect and share feedback based on the following questions. Add these questions to your pulse survey or feedback tool of choice:",
    These_micro_learning_content:
      "These micro-learning content snippets can be shared through various channels such as emails, company intranet, social media, or a learning management system. They are intended to be short and easily digestible, allowing employees to reflect on and improve upon the core values in a quick and convenient manner.",
    Generate_more_content: "Generate more content",
    Create_an_account_to:
      "Create an account to unlock skills, survey questions, and microlearning",
    Download_This_Report: "Download This Report",
    Download_to_save: "Save this core values audit and analysis as a PDF file",
    Download: "Download",
    Download_pdf_title:
      "A Core Values Report: An Audit, Skill & Development Guidance, Survey Questions, and Microlearning",
    Download_pdf_success: "Preparing to download the report...",
  },
  headerTable: {
    NAME: "NAME",
    CANDIDATES: "CANDIDATES",
    PROGRESS: "PROGRESS",
    LAST_ACTIVITY: "LAST ACTIVITY",
    DATE_CREATED: "DATE CREATED",
    ACTIONS: "ACTIONS",
    JOINED: "JOINED",
    SCORE: "SCORE",
    STATUS: "STATUS",
    YOUR_RATING: "YOUR RATING",
  },
  dataTable: {
    Invite_your_first: "Invite your first candidate or team member",
    Send_invitations: "Send invitations by email or sharing a link",
  },
  popup: {
    optional: {
      Yes: "Yes",
      Yes_chi: "Yes",
      Confirm: "Confirm",
      Cancel: "Cancel",
      Continue: "Continue",
      Go_to_Next_Section: "Go to Next Section",
      OK: "OK",
      Got_it: "Got it",
      Confirm_Delete: "Confirm Delete",
    },
    question: {
      question_box_1: {
        title: "Send results to ",
        question_1: "We will send an email, on your behalf, to ",
        question_2:
          " with a private link to access their results. Are you sure you would like to proceed?",
      },
      question_box_2: {
        title: "Warning",
        question: "Are you sure you want to delete this submission?",
      },
      question_box_3: {
        title: "Warning",
        question: "Are you sure you want to delete this candidate?",
      },
      question_box_4: {
        title: "Set up",
        question: "Do you want to edit your current set up process?",
      },
      question_box_5: {
        title: "Warning",
        question:
          "Are you sure you want to remove this scenario from being evaluated for this assessment?",
      },
      save_team: {
        success_msg: "Team has been changed successfully",
      },
      delete_team_success: {
        success_msg: "Team has been deleted successfully",
      },
      add_member: {
        success_msg: "Member has been added successfully",
      },
      change_role: {
        success_msg: "Member role has been changed successfully",
      },
      remove_member: {
        title: "Warning",
        question: "Are you sure you want to remove this member?",
        success_msg: "Member has been removed successfully",
      },
      leave_team: {
        title: "Warning",
        question: "Are you sure you want to leave from this team?",
        success_msg: "You have left from this team successfully",
      },
      cannot_be_admin: {
        title: "Warning",
        question:
          "Are you sure you want to remove yourself from administrator?",
      },
      delete_team: {
        title: "Delete the {teamName} Team",
        question: `Are you sure you want to delete this team? Once deleted, all team assessments and results will no longer be available to view by any team member.

        <br /><br />Please proceed with caution.`,
      },
      clear_results: {
        title: "Clear Results",
        question: `Are you sure you want to clear all results so that the candidate must retake the assessment from the beginning?`,
      },
    },
    alert: {
      alert_box_1: {
        title: "No time remaining",
        description:
          "The allocated time for this section has run out. Please continue to the next step.",
      },
      alert_box_2: {
        title: "Session Time Out",
        description:
          "Please proceed to the next section of the assessment to complete your submission. Otherwise, you are at risk of losing your responses and may have to re-take the assessment.",
      },
      question_box_5: {
        title: "Warning",
        question:
          "Are you sure you want to remove this scenario from being evaluated for this assessment?",
      },
      cannot_remove_last_admin: {
        title: "Warning",
        message:
          "One admin is required per team. Please assign another member as an Admin before proceeding.",
      },
      cannot_add_duplicated_member: {
        title: "Warning",
        message: "Already have this member.",
      },
    },
    message: {
      message_1: "You have sent assessment results",
      message_2: "An invitation email has been resent to ",
      message_3_1: "The results of ",
      message_3_2: " assessment has been successfully cleared",
      message_4: "Data not found.",
      message_5e: "This image must be a JPG or PNG!",
      message_6e: "This image is bigger than 2MB!",
      message_7e: "Invalid email or OTP. Please check again.",
      message_8: "Copy to clipboard",
      message_9e:
        "No credits remaining. Refer a friend (My Account) to earn more!",
      message_10e: "Maximum emails to send is ",
      message_11: "Emails sent",
      message_12: "Data not found.",
      message_13_1: "An invitation email has been sent to ",
      message_13_2: "",
      message_14w: "Request: 1 section!",
      message_15w: "Please complete your setup before proceeding",
      message_16e: "Oops, something went wrong!",
      message_17: "Referral link copied to your clipboard.",
      message_18: "Saved. User details have been updated.",
      message_18b: "Saved. Company details have been updated.",
      message_19: "The link to this report has been copied to your clipboard.",
      message_20w: "You can only have a maximum of 100 recipients.",
    },
  },
  datetime: {
    months: {
      January: "January",
      February: "February",
      March: "March",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
    },
  },
  coupon: {
    error_messages: {
      message_1: "Coupon code is required.",
      message_2: "Coupon code was not found.",
      message_3: "The coupon code has already expired.",
      message_4: "Coupon usage limit has been reached.",
      message_5: "Coupon already used.",
    },
  },
  toolbox: {
    Role: "Role",
    Level: "Level",
    Role_Level: "Role Level",
    Type_of_organization: "Type of Organization",
    Core_values: "Core Values",
    Company_Mission_n_Vision: "Company Mission & Vision",
    Select_a_Role_and_Level: "Select a Role and Level",
    hiring: "Hiring",
    onboarding: "Onboarding",
    people_management: "People Management",
  },
  core_values_analyzer: {
    title: "Core Values Analyzer",
    short_desc: "Audit and get value-aligned recommendations",
  },
  job_role_generator: {
    Job_role_generator: "Team Role Generator",
    short_desc: "Define roles, behaviors, and promotion paths",
    Your_role_generator_that_makes:
      "Your role generator that makes accountability, responsibility, behaviors, and path to promotion clear.",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "Add your core values to your role description to bring them to life",
  },
  job_post_generator: {
    Job_post_generator: "Job Post Generator",
    short_desc: "Autocraft detailed and effective job posts",
    Your_post_generator_that_makes:
      "Your job post generator that crafts a job post complete with responsibilities, qualifications, and expectations.",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "Add your core values to your job post to bring them to life",
  },
  interview_guide: {
    Interview_guide: "Interview Guide",
    short_desc: "A script to run effective interviews",
    long_desc:
      "A set of questions and case study to assist your interview process",
    introduction:
      "Add your core values to your interview guide to bring them to life",
  },
  career_path_tracker: {
    Career_path_tracker: "Career Tracker Generator",
    short_desc: "Visualize and guide team member growth",
    Your_post_generator_that_makes:
      "Create a comprehensive career tracker to clarify personal growth opportunities.",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "Add your core values to your career path tracker to bring them to life",
  },
  okr_generator: {
    Okr_generator: "OKR & Action Plans Generator",
    short_desc: "Set and track clear objectives and results",
    Your_OKR_generator_that_crafts_objectives_and_key_results_that_are_clear_and_measurable:
      "Your OKR generator that crafts objectives and key results that are clear and measurable",
    Select_between_Org_Team_or_Individual_OKRs:
      "Select between Org, Team, or Individual OKRs",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "Add your core values to your OKRs to bring them to life",
    Title_for_company: "OKRs for {company}",
    Title_for_team: "OKRs for {role} Team",
    Title_for_individual: "OKRs for {role} {level}",
  },
  performance_improvement: {
    title: "Performance Improvement Plan",
    short_desc: "Structured plan for performance enhancement",
    subtitle:
      "Create a structured and clear plan to guide a team member to meeting or exceeding expectations",
    suggestion:
      "Add your core values to your performance improvement plan to bring them to life",
  },
  num_day_plan: {
    title: "30/60/90-day Plan",
    short_desc: "Structured 30/60/90-day integration plan",
    subtitle:
      "Create an onboarding plan for a new hire with clear activities and expectations",
    suggestion:
      "Add your core values to your onboarding plan to bring them to life",
  },
};

export default en;
