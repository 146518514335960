import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  setDoc,
  limit,
} from "firebase/firestore";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    apiKey: "",
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    apiKey(state: { apiKey: string }, apiKey: string): any {
      state.apiKey = apiKey;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    apiKey(state: { apiKey: string }): any {
      return state.apiKey;
    },
  },
  actions: {
    async generate(
      { commit }: { commit: any; dispatch: any },
      formDetails: { teamId: string }
    ): Promise<any> {
      commit("loading", true);

      // "12345678-abcd-1234-efgh-ijklmnopqrst"

      const genSerialNum = (type = "all", serialLenght = 20) => {
        // let chars = "123456ABCDEFGHI76wndixdzsfszfs2009765210ojfngdBNMUOP";
        let chars =
          "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        if (type == "number") {
          chars = "0123456789";
        } else if (type == "char") {
          chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }
        let serialNum = "";
        for (let i = 0; i < serialLenght; ++i) {
          const char = chars[Math.floor(Math.random() * chars.length)];
          serialNum += char;
        }
        return serialNum;
      };

      let done = false;
      let apiKey = "";

      const fs = getFirestore();

      do {
        apiKey =
          genSerialNum("number", 8) +
          "-" +
          genSerialNum("char", 4) +
          "-" +
          genSerialNum("number", 4) +
          "-" +
          genSerialNum("char", 4) +
          "-" +
          genSerialNum("char", 12);
        // apiKey = "29507537-DCIP-8429-ZEwv-QmrtmemROhvs";

        const q = query(
          collection(fs, "teams"),
          where("greenhouse.api_key", "==", apiKey),
          limit(1)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length == 1) {
          console.log("duplicated..");
          continue;
        }
        done = true;
        break;
      } while (!done);

      const teamId = formDetails.teamId;
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      const values = {
        greenhouse: {
          api_key: apiKey,
        },
      };
      await setDoc(doc(fs, "teams", teamId), values, { merge: true });

      commit("apiKey", apiKey);
      commit("loading", false);
    },
  },
};
