import { createStore } from "vuex";

import routes from "./modules/routes";

// @see https://v3.vuex.vuejs.org/guide/structure.html
import auth from "./modules/auth";
import google from "./modules/google";
import microsoft from "./modules/microsoft";
import user from "./modules/user";

// lab: try to develop form validation, model, pass form value from anywhere
import CreateAccountForm from "./forms/CreateAccount";

// assessment
import assessmentSection from "./modules/assessment-section";
import assessmentTemplate from "./modules/assessment-template";
import assessment from "./modules/assessment";
import respondent from "./modules/respondent";

// performance
import caches from "./modules/caches";

// Billing & Payment
import quota from "./modules/quota";
import payment from "./modules/payment";
import coupon from "./modules/coupon";
import referral from "./modules/referral";

// My Core Values
import coreValuesAnalyzer from "./modules/core-values-analyzer";
// import aiAssistedSetup from "./modules/ai-assisted-setup";
import toolbox from "./modules/toolbox";
import jobRoleGenerator from "./modules/job-role-generator";
import jobPostGenerator from "./modules/job-post-generator";
import careerPathTracker from "./modules/career-path-tracker";
import okrGenerator from "./modules/okr-generator";
import performanceImprovement from "./modules/performance-improvement";
import numDayPlan from "./modules/num-day-plan";
import interviewGuide from "./modules/interview-guide";

// Team
import team from "./modules/team";

// Integrations
import greenhouse from "./modules/greenhouse";

import aiAssessmentTemplate from "./modules/ai-assessment-template";

import sampleData from "./sample-data/sample-data";

// setup
import fixtures from "./setup/fixtures";

export default createStore({
  state: {
    userLogged: false,
    loadingStatus: false,
    isReady: false,
  },
  mutations: {
    userLoggedStatus(state, value) {
      state.userLogged = value;
    },
    loadingStatus(state, status) {
      state.loadingStatus = status;
    },
    isReady(state, isReady) {
      state.isReady = isReady;
    },
  },
  actions: {
    userLoginWidthGoogle({ commit }, value) {
      console.log("STORE: userLoginWidthGoogle func..");
      commit("loadingStatus", true);
      // API > commit
      setTimeout(() => {
        commit("loadingStatus", false);
        commit("userLoggedStatus", value);
      }, 3000);
    },
    isReady({ commit }, isReady) {
      commit("isReady", isReady);
    },
    forceReady({ dispatch, rootState }) {
      // used for Hot Code Push
      if (rootState.isReady) {
        dispatch("isReady", false);
      }
      dispatch("isReady", true);
    },
  },
  modules: {
    routes,
    auth,
    google,
    microsoft,
    user,
    CreateAccountForm,
    assessmentSection,
    assessmentTemplate,
    assessment,
    caches,
    quota,
    payment,
    coupon,
    referral,
    fixtures,
    respondent,
    coreValuesAnalyzer,
    // aiAssistedSetup,
    toolbox,
    jobRoleGenerator,
    jobPostGenerator,
    careerPathTracker,
    okrGenerator,
    performanceImprovement,
    numDayPlan,
    interviewGuide,
    team,
    greenhouse,
    aiAssessmentTemplate,
    sampleData,
  },
  getters: {
    getLoginStatus(state) {
      return state.userLogged;
    },
    getLoadingStatus(state) {
      return state.loadingStatus;
    },
    isReady(state) {
      return state.isReady;
    },
  },
});
