import {
  getFirestore,
  collection,
  query,
  where,
  limit,
  orderBy,
  getDocs,
} from "firebase/firestore";

// import helpers from "@/helpers/global";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    // defining_your_values and identifying_key_traits
    valuesAndTraits: null,
    oaicArchetypes: null,
    acceptableBehaviors: null,
    workStyleIdentifier: null,
    logicTest: null,
    allQuestions: null,
    valuesAndTraitsVersionId: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    valuesAndTraits(
      state: { valuesAndTraits: any },
      valuesAndTraits: {
        definingYourValues: any;
        identifyingKeyTraits: any;
      }
    ): any {
      state.valuesAndTraits = valuesAndTraits;
    },
    oaicArchetypes(
      state: { oaicArchetypes: any },
      oaicArchetypes: {
        criteriaOfSuccess: any;
        dominantCharacteristics: any;
        managementOfEmployees: any;
        organizationGlue: any;
        organizationalLeadership: any;
        strategicEmphases: any;
      }
    ): any {
      state.oaicArchetypes = oaicArchetypes;
    },
    acceptableBehaviors(
      state: { acceptableBehaviors: any },
      acceptableBehaviors: {
        scenario1: any;
        scenario2: any;
        scenario3: any;
        scenario4: any;
        scenario5: any;
        scenario6: any;
      }
    ): any {
      state.acceptableBehaviors = acceptableBehaviors;
    },
    workStyleIdentifier(
      state: { workStyleIdentifier: any },
      workStyleIdentifier: {
        scenario1: any;
      }
    ): any {
      state.workStyleIdentifier = workStyleIdentifier;
    },
    logicTest(
      state: { logicTest: any },
      logicTest: {
        scenario1: any;
      }
    ): any {
      state.logicTest = logicTest;
    },
    allQuestions(
      state: { allQuestions: any },
      allQuestions: { id?: any }
    ): any {
      state.allQuestions = allQuestions;
    },
    valuesAndTraitsVersionId(
      state: { valuesAndTraitsVersionId: string },
      valuesAndTraitsVersionId: string
    ): any {
      state.valuesAndTraitsVersionId = valuesAndTraitsVersionId;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    valuesAndTraits(state: { valuesAndTraits: any }): any {
      return state.valuesAndTraits;
    },
    oaicArchetypes(state: { oaicArchetypes: any }): any {
      return state.oaicArchetypes;
    },
    acceptableBehaviors(state: { acceptableBehaviors: any }): any {
      return state.acceptableBehaviors;
    },
    workStyleIdentifier(state: { workStyleIdentifier: any }): any {
      return state.workStyleIdentifier;
    },
    logicTest(state: { logicTest: any }): any {
      return state.logicTest;
    },
    allQuestions(state: { allQuestions: any }): any {
      return state.allQuestions;
    },
    valuesAndTraitsVersionId(state: { valuesAndTraitsVersionId: string }): any {
      return state.valuesAndTraitsVersionId;
    },
  },
  actions: {
    async loadSetupByName(
      {
        commit,
        dispatch,
        getters,
      }: { commit: any; dispatch: any; getters: any },
      name: string
    ): Promise<any> {
      // console.log(`loadSectionByName(${name})`);
      // console.trace();
      commit("loading", true);
      const db = getFirestore();
      const q = query(
        collection(db, "assessment_sections"),
        where("name", "==", name)
      );
      const scenarios: any = {};
      const allQuestions: any = {};
      const querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const doc = querySnapshot.docs[i];
        const path = `assessment_sections/${doc.id}/scenarios`;
        const q = query(collection(db, path), orderBy("order", "asc"));
        const $querySnapshot = await getDocs(q);
        const total = $querySnapshot.size;
        let totalProgress = 0;
        for (const j in $querySnapshot.docs) {
          const scenario = $querySnapshot.docs[j];
          const data = scenario.data();
          const key = _.camelCase(
            (data.name || "").replace(/\s/gi, "_").toLowerCase()
          );
          // console.log(key, "key");
          // @todo scenarios should be ordered and used by loop NOT hard code
          // by specific every section like this
          scenarios[key] = { id: scenario.id, ...data, questions: [] };

          // find questions
          const path = `assessment_sections/${doc.id}/scenarios/${scenario.id}/questions`;
          const q = query(collection(db, path), orderBy("order", "asc"));
          // const q = query(
          //   collection(db, path),
          //   limit(5),
          //   orderBy("order", "asc")
          // );
          const $$querySnapshot = await getDocs(q);
          // const size = $$querySnapshot.size;
          for (const k in $$querySnapshot.docs) {
            // console.log(k, "k", size, "size", $$querySnapshot.docs.length, "length");
            const question = $$querySnapshot.docs[k];
            const data = question.data();
            const disabled = data.disabled || false;
            // if (disabled) continue;

            if (!disabled) {
              allQuestions[question.id] = data;

              const value = data.type == "avg_percent" ? 0 : "";
              scenarios[key].questions.push({
                id: question.id,
                ...data,
                value: value,
                dirty: false,
              });

              // checkbox or radio
              if (data.type == "choice") {
                scenarios[key].questions[
                  scenarios[key].questions.length - 1
                ].checked = false;
              }
            }

            if (Number(k) + 1 == $$querySnapshot.docs.length) {
              totalProgress++;
              if (totalProgress == total) {
                const section = {
                  id: doc.id,
                  ...doc.data(),
                  scenarios: scenarios,
                };
                // console.log(
                //   "before cache: " + name,
                //   _.camelCase(name),
                //   section
                // );
                const values: any = {};
                values[name] = section;
                dispatch(
                  "caches/set",
                  { name: "assessment_sections", values: values },
                  {
                    root: true,
                  }
                );
                commit(_.camelCase(name), section);
                commit("loading", false);
              }
            }
          }
        }
      }
      const _allQuestions = getters.allQuestions || {};
      const newAllQuestions = _.assign({}, _allQuestions, allQuestions);
      commit("allQuestions", newAllQuestions);

      if (name == "values_and_traits") {
        await dispatch("afterloadSetupValuesAndTraits");
      }
    },
    async loadSetupByNames(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      settingSections: string[]
    ): Promise<any> {
      // console.log("loadSetupByNames()");
      // console.log(settingSections, "settingSections");
      const allowedSections = [
        "values_and_traits",
        "oaic_archetypes",
        "acceptable_behaviors",
        "work_style_identifier",
        "logic_test",
      ];
      settingSections = _.map(settingSections, (settingSection) => {
        return settingSection.toLowerCase();
      });
      settingSections = _.intersection(allowedSections, settingSections);
      // console.log(settingSections, "settingSections");

      const cachedId = "assessment_sections";
      await dispatch("caches/load", cachedId, { root: true });
      // console.log(rootState, "rootState");

      const DO_NOT_CACHED = false;
      const caches = rootState.caches[_.camelCase(cachedId)];
      for (const index in settingSections) {
        const key = settingSections[index];
        if (!DO_NOT_CACHED && caches && caches[key]) {
          const section = caches[key];
          // console.log(key, "key");
          // console.log(section, "section");
          commit(_.camelCase(key), section);
        } else {
          // console.time(`loadSetupByName(${key})`);
          await dispatch("assessmentSection/loadSetupByName", key, {
            root: true,
          });
          // console.timeEnd(`loadSetupByName(${key})`);
        }
      }
    },
    setValuesAndTraitsVersionId(
      { commit }: { commit: any; dispatch: any; getters: any },
      valuesAndTraitsVersionId: string
    ): any {
      commit("valuesAndTraitsVersionId", valuesAndTraitsVersionId);
    },
    async initValuesAndTraits({ dispatch }: { dispatch: any }): Promise<any> {
      // console.log("initValuesAndTraits()");
      const fs = getFirestore();
      // console.log("before: query");
      const q = query(
        collection(fs, "value_alignment_versions"),
        where("enabled", "==", true),
        orderBy("name", "desc"),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      // console.log("after: query");
      const valuesAndTraitsVersionId = querySnapshot.docs[0]
        ? querySnapshot.docs[0].id
        : null;
      // console.log("valuesAndTraitsVersionId", valuesAndTraitsVersionId);
      dispatch("setValuesAndTraitsVersionId", valuesAndTraitsVersionId);
    },
    async afterloadSetupValuesAndTraits({
      getters,
      commit,
    }: {
      getters: any;
      commit: any;
    }): Promise<any> {
      // console.log("afterloadSetupValuesAndTraits()");
      // console.trace();
      // @todo mark version_id to this form

      const valuesAndTraitsVersionId = getters.valuesAndTraitsVersionId;
      // console.log(valuesAndTraitsVersionId, "valuesAndTraitsVersionId");
      if (!valuesAndTraitsVersionId) {
        return;
      }

      // console.log("call me");

      const fs = getFirestore();
      const allQuestions: any = {};
      const q = query(
        collection(fs, "value_alignments"),
        where("version_id", "==", valuesAndTraitsVersionId)
      );
      const $querySnapshot = await getDocs(q);
      const values = [];
      for (const i in $querySnapshot.docs) {
        const document = $querySnapshot.docs[i];
        const data = document.data();
        const value = {
          id: document.id,
          ...data,
          title_th: data.title_th || data.title,
          value: "",
          dirty: false,
        };
        values.push(value);
        allQuestions[document.id] = value;
      }

      // remove all questions from values_and_traits (only value alignment)
      const _allQuestions = getters.allQuestions || {};

      const valuesAndTraits = getters.valuesAndTraits || {};
      const questions = _.get(
        valuesAndTraits,
        "scenarios.definingYourValues.questions",
        []
      );
      for (const i in questions) {
        const question = questions[i];
        if (_.has(_allQuestions, question.id)) {
          delete _allQuestions[question.id];
        }
      }

      // add value alignment to all questions
      const newAllQuestions = _.assign({}, _allQuestions, allQuestions);
      commit("allQuestions", newAllQuestions);

      _.set(valuesAndTraits, "scenarios.definingYourValues.questions", values);
      _.set(
        valuesAndTraits,
        "scenarios.definingYourValues.version_id",
        valuesAndTraitsVersionId
      );

      commit("valuesAndTraits", valuesAndTraits);
    },
    async loadSetupValuesAndTraits({
      dispatch,
    }: {
      dispatch: any;
      getters: any;
      commit: any;
    }): Promise<any> {
      // console.log("loadSetupValuesAndTraits()");
      const name = "values_and_traits";
      await dispatch("loadSetupByName", name);
    },
    async loadSetupOaicArchetypes({
      dispatch,
    }: {
      dispatch: any;
    }): Promise<any> {
      const name = "oaic_archetypes";
      await dispatch("loadSetupByName", name);
    },
    async loadSetupAcceptableBehaviors({
      commit,
      dispatch,
      rootState,
    }: {
      commit: any;
      dispatch: any;
      rootState: any;
    }): Promise<any> {
      // TODO: add param caching for loadSetupByName (params should be object type!)
      // console.log("loadSetupAcceptableBehaviors()");
      const DO_NOT_CACHED = false;
      const name = "acceptable_behaviors";
      if (DO_NOT_CACHED) {
        await dispatch("loadSetupByName", name);
      } else {
        const cachedId = "assessment_sections";
        await dispatch("caches/load", cachedId, { root: true });
        const caches = rootState.caches[_.camelCase(cachedId)];
        const key = name;
        if (caches && caches[key]) {
          const section = caches[key];
          // console.log(key, "key");
          // console.log(section, "section");
          if (section) {
            commit(_.camelCase(key), section);
          } else {
            // TODO: DO NOT use this style
            await dispatch("loadSetupByName", name);
          }
        }
      }
    },
  },
};
