// alias public_types.ts

import type { Auth, Provider, SignupFormDetails } from "./auth";
import type { User, Google, Microsoft, UserDetails } from "./user";

enum AppSettings {
  DATETIME_FORMAT = "yyy-MM-dd HH:mm:ss",
}

export { Auth, Provider, User, SignupFormDetails };
export { Google, Microsoft, UserDetails };

export default AppSettings;
