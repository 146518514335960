import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  query,
  getDocs,
  collection,
  where,
  limit,
} from "firebase/firestore";

import helpers from "@/helpers/global";
import _ from "lodash";

// Collection Name
const CN = "caches";

// @fixed large uuid from company mssion and vision
const FORCE_QUERY_OPENAPI = true;
const OPENAI_OPT_STREAM = true;

// const OPENAI_MODEL_DEFAULT = "gpt-4";
// const OPENAI_MODEL_DEFAULT = "gpt-3.5-turbo";
// const OPENAI_MODEL_DEFAULT = "gpt-4-turbo-preview";
const OPENAI_MODEL_DEFAULT = "gpt-4o";

const OPENAI_ENDPOINT = "https://api.openai.com/v1/chat/completions";

const DEFAULT_PARAMS = {
  model: OPENAI_MODEL_DEFAULT,

  temperature: 0.01,
  max_tokens: 1024,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  stream: OPENAI_OPT_STREAM,
};

// const PROMPT_ROLE_SYSTEM = "";

let PROMPT_JOB_ROLE = ``;

const PROMPT_JOB_ROLE_INDIVIDUAL = `You're a strategic leadership expert tasked to produce ready-to-use Objectives, Key Results (OKRs), and Action Plans. The OKRs should follow industry best practices with 2 clear objectives, each with 3 key results, each with 2 action plans relevant to the organization type, level, and role (as applicable). Design the OKRs and action plans to be descriptive and clear, aligning them with the company's core values: <%= coreValues %>.\n\nKey features of the OKRs & Action Plans:\n- Objectives are qualitative. They should be ambitious, short, and inspiring. They should instill a sense of meaning and excite you out of bed in the morning. Ambitious objectives make teams strive for peak performance. The philosophy behind OKRs is that if your company is achieving 100% of its goals, the goals are too easy.\n- Key results are the metrics you use to track progress toward each objective. The best KRs are quantitative, possible, and measurable.\n- Objectives and results should be business outcome-focused more than activity-focused.\n- Provide a suggestion of how each key result can be measured.\n- An Action Plan Item should be comprehensive, detailed, and developed with the same sophistication as McKinsey & Co, Bain, or BCG.\n\nFormatting Guidelines: Do not include a header or title. Go straight to the first OKR and format the text using markdown.\n\nThe organization is a <%= TypeOfOrganization %> called <%= CompanyName %> with the following mission: <%= CompanyMissionAndVision %>.\n\n Individual-level OKRs for the <%= Role %> role for the <%= Level %> level. Structure it in a way that it can be easily copied or repurposed.`;

const PROMPT_JOB_ROLE_TEAM = `You're a strategic leadership expert tasked to produce ready-to-use Objectives, Key Results (OKRs), and Action Plans. The OKRs should follow industry best practices with 2 clear objectives, each with 3 key results, each with 2 action plans relevant to the organization type, level, and role (as applicable). Design the OKRs and action plans to be descriptive and clear, aligning them with the company's core values: <%= coreValues %>.\n\nKey features of the OKRs & Action Plans:\n- Objectives are qualitative. They should be ambitious, short, and inspiring. They should instill a sense of meaning and excite you out of bed in the morning. Ambitious objectives make teams strive for peak performance. The philosophy behind OKRs is that if your company is achieving 100% of its goals, the goals are too easy.\n- Key results are the metrics you use to track progress toward each objective. The best KRs are quantitative, possible, and measurable.\n- Objectives and results should be business outcome-focused more than activity-focused.\n- Provide a suggestion of how each key result can be measured.\n- An Action Plan Item should be comprehensive, detailed, and developed with the same sophistication as McKinsey & Co, Bain, or BCG.\n\nFormatting Guidelines: Do not include a header or title. Go straight to the first OKR and format the text using markdown.\n\nThe organization is a <%= TypeOfOrganization %> called <%= CompanyName %> with the following mission: <%= CompanyMissionAndVision %>.\n\nCreate team-level OKRs for the <%= Role %> team. Structure it in a way that it can be easily copied or repurposed.`;

const PROMPT_JOB_ROLE_ORGANIZATION = `You're a strategic leadership expert tasked to produce ready-to-use Objectives, Key Results (OKRs), and Action Plans. The OKRs should follow industry best practices with 2 clear objectives, each with 3 key results, each with 2 action plans relevant to the organization type, level, and role (as applicable). Design the OKRs and action plans to be descriptive and clear, aligning them with the company's core values: <%= coreValues %>.\n\nKey features of the OKRs & Action Plans:\n- Objectives are qualitative. They should be ambitious, short, and inspiring. They should instill a sense of meaning and excite you out of bed in the morning. Ambitious objectives make teams strive for peak performance. The philosophy behind OKRs is that if your company is achieving 100% of its goals, the goals are too easy.\n- Key results are the metrics you use to track progress toward each objective. The best KRs are quantitative, possible, and measurable.\n- Objectives and results should be business outcome-focused more than activity-focused.\n- Provide a suggestion of how each key result can be measured.\n- An Action Plan Item should be comprehensive, detailed, and developed with the same sophistication as McKinsey & Co, Bain, or BCG.\n\nFormatting Guidelines: Do not include a header or title. Go straight to the first OKR and format the text using markdown.\n\nThe organization is a <%= TypeOfOrganization %> called <%= CompanyName %> with the following mission:  <%= CompanyMissionAndVision %>.\n\nCreate Organization-level OKRs. Structure it in a way that it can be easily copied or repurposed.`;

const request = async function (params: { messages: any[] }) {
  const params_: any = { ...DEFAULT_PARAMS, ...params };

  const endpoint = OPENAI_ENDPOINT;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
    },
    body: JSON.stringify(params_),
  };

  const response = await fetch(endpoint, requestOptions);
  const data = await response.json();
  return data.choices[0].message.content;
};

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    result: null,
    title: "",
    subtitle: "",
    isTyping: false,
    locked: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    result(state: { result: string }, result: string): any {
      state.result = result;
    },
    title(state: { title: string }, title: string): any {
      state.title = title;
    },
    subtitle(state: { subtitle: string }, subtitle: string): any {
      state.subtitle = subtitle;
    },
    isTyping(state: { isTyping: boolean }, isTyping: boolean): any {
      state.isTyping = isTyping;
    },
    locked(state: { locked: boolean }, locked: boolean): any {
      state.locked = locked;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    result(state: { result: string }): any {
      return state.result;
    },
    title(state: { title: string }): any {
      return state.title;
    },
    subtitle(state: { subtitle: string }): any {
      return state.subtitle;
    },
    isTyping(state: { isTyping: boolean }): any {
      return state.isTyping;
    },
    locked(state: { locked: boolean }): any {
      return state.locked;
    },
  },
  actions: {
    async generate(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      {
        targetLevel,
        role,
        level,
        typeOfOrg,
        coreValues,
        missionAndVision,
      }: {
        targetLevel: string;
        role?: string;
        level?: string;
        typeOfOrg: string;
        coreValues: string[];
        missionAndVision: string;
      }
    ): Promise<any> {
      // get prompt by target level
      // always reset prompt
      PROMPT_JOB_ROLE = ``;
      switch (targetLevel) {
        case "Individual":
          PROMPT_JOB_ROLE = PROMPT_JOB_ROLE_INDIVIDUAL;
          break;
        case "Team":
          PROMPT_JOB_ROLE = PROMPT_JOB_ROLE_TEAM;
          break;
        case "Organization":
          PROMPT_JOB_ROLE = PROMPT_JOB_ROLE_ORGANIZATION;
          break;
      }

      if (!PROMPT_JOB_ROLE.length) {
        commit("error", `Not found prompt for target level ${targetLevel}`);
        return;
      }

      const credits = 1;

      const quotaData = rootState.quota.data;
      if (!quotaData.unlimited) {
        if (quotaData.quota_remaining < credits) {
          commit("error", "Not enough quota");
          return;
        }
      }

      commit("result", null);
      commit("loading", true);
      commit("locked", true);

      let $coreValues = "";
      if (coreValues.length) {
        if (coreValues.length > 1) {
          const _coreValues = _.clone(coreValues);
          const last = _coreValues.pop();
          $coreValues = _coreValues.join(", ") + " and " + last;
        } else {
          $coreValues = coreValues[0];
        }
      }

      const variables = {
        CompanyName: _.get(rootState, "user.user.companyName"),
        TypeOfOrganization: typeOfOrg,
        coreValues: $coreValues,
        CompanyMissionAndVision: missionAndVision,
      } as any;

      let title = "";
      switch (targetLevel) {
        case "Individual":
          variables.Role = role;
          variables.Level = level;
          title = helpers.t("okr_generator.Title_for_individual", {
            role: variables.Role,
            level: variables.Level,
          });
          break;
        case "Team":
          variables.Role = role;
          title = helpers.t("okr_generator.Title_for_team", {
            role: variables.Role,
          });
          break;
        case "Organization":
          title = helpers.t("okr_generator.Title_for_company", {
            company: variables.CompanyName,
          });
          break;
      }

      // @todo subject for each target level
      commit("title", title);
      commit("subtitle", coreValues.join(" • "));

      let result;
      let cacheId: any;
      let cacheDetails: any;
      let responseText: string;
      let error;

      const cachePrefix = "prompt_okrgenerator";
      const newVariables: any = _.cloneDeep(variables);
      delete newVariables.CompanyMissionAndVision;
      const uuid =
        cachePrefix + "=" + new URLSearchParams(newVariables).toString();

      const fs = getFirestore();
      const q = query(collection(fs, CN), where("uuid", "==", uuid), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc: any) => {
        cacheId = String(doc.id);
        cacheDetails = doc.data();
        result = cacheDetails.data;
      });

      const writeCache = async (
        params: any,
        _responseText: string,
        result: string
      ) => {
        const idPrefix = `${cachePrefix}-`;
        let notFound = true;
        let id = "";
        do {
          id = idPrefix + helpers.generateString(32);
          const docRef = doc(fs, CN, id);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            cacheDetails = {
              scope: cachePrefix,
              params: params,
              responseText: _responseText,
              data: result,
              uuid: uuid,
              created_at: helpers.now(),
            };
            cacheId = id;
            notFound = false;
          }
        } while (notFound);

        if (cacheDetails) {
          setDoc(doc(fs, CN, cacheId), cacheDetails, {
            merge: true,
          });
        }
      };

      // use credit before use openai becauset it's stream
      const teamId = rootState.user.user.teamId;
      await dispatch(
        "quota/useCredits",
        { teamId, credits: credits, scope: cachePrefix, variables: variables },
        { root: true }
      );

      if (!cacheId || FORCE_QUERY_OPENAPI) {
        const params = {
          messages: [] as any,
        };
        const content = _.template(PROMPT_JOB_ROLE)(variables);
        if (content.length) {
          // params.messages.push({ role: "system", content: PROMPT_ROLE_SYSTEM });
          params.messages.push({ role: "user", content: content });
        }

        //*
        try {
          if (OPENAI_OPT_STREAM) {
            const params_: any = { ...DEFAULT_PARAMS, ...params };
            const endpoint = OPENAI_ENDPOINT;
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
              },
              body: JSON.stringify(params_),
            };
            fetch(endpoint, requestOptions)
              .then((response: any) => {
                const reader = response.body.getReader();
                let resonseText = "";
                commit("loading", false);
                commit("isTyping", true);
                reader.read().then(function pump({ done, value }: any) {
                  if (done) {
                    // Do something with last chunk of data then exit reader
                    writeCache(params, resonseText, resonseText);
                    commit("isTyping", false);
                    commit("locked", false);
                    return;
                  }
                  // Otherwise do something here to process current chunk
                  const text = new TextDecoder().decode(value);
                  const prefix = "data: ";
                  const pos = text.indexOf(prefix);
                  if (pos === 0) {
                    const lines = text.split("\n");
                    for (const i in lines) {
                      if (!lines[i]) continue;
                      if (lines[i].indexOf(prefix) === 0) {
                        const v = lines[i].substring(prefix.length);
                        if (v === "[DONE]") continue;
                        const value = JSON.parse(v);
                        if (_.isObject(value)) {
                          const content = _.get(
                            value,
                            "choices[0].delta.content"
                          );
                          // console.log(content, "content");
                          if (content) {
                            resonseText += String(content);
                          }
                          commit("result", resonseText);
                          // console.log(resonseText, "resonseText");
                        }
                      }
                    }
                  }
                  // Read some more, and call this function again
                  return reader.read().then(pump);
                });
              })
              .catch((e: unknown) => {
                error = true;
                if (e instanceof Error) {
                  commit("error", e.message);
                }
                console.error(e);
              });
          } else {
            responseText = await request(params);
            result = responseText;
            writeCache(params, responseText, result);
            commit("locked", false);
          }
          error = false;
        } catch (e: unknown) {
          error = true;
          if (e instanceof Error) {
            error && commit("error", e.message);
          }
          console.error(e);
        }
      } else {
        commit("locked", false);
      }

      /*
      if (result) {
        commit("isTyping", true)
        const lines = result.split("\n");
        let text = "";
        for (const i in lines) {
          setTimeout(() => {
            text += lines[i] + "\n";
            commit("result", text);
            if (Number(i) + 1 == lines.length) {
              commit("isTyping", false);
            }
          }, Number(i) * 500);
        }
      }
      //*/

      await dispatch("quota/load", rootState.user.user, { root: true });

      commit("result", result);
      commit("loading", false);
    },
  },
};
