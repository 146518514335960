import {
  getFirestore,
  collection,
  query,
  where,
  and,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  orderBy,
  limit,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import _ from "lodash";
import helpers from "@/helpers/global";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    teamId: "",
    members: [],
    personalTeam: null,
    team: null,
    companyLogoUrl: null,
    forceReload: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    teams(state: { teams: any[] }, teams: any[]): any {
      state.teams = teams;
    },
    teamIds(state: { teamIds: string[] }, teamIds: string[]): any {
      state.teamIds = teamIds;
    },
    activeTeamId(state: { activeTeamId: string }, activeTeamId: string): any {
      state.activeTeamId = activeTeamId;
    },
    members(state: { members: any[] }, members: any[]): any {
      state.members = members;
    },
    personalTeam(
      state: { personalTeam: any },
      personalTeam: { _id: string }
    ): any {
      state.personalTeam = personalTeam;
    },
    team(state: { team: any }, team: { _id: string }): any {
      state.team = team;
    },
    companyLogoUrl(
      state: { companyLogoUrl: string },
      companyLogoUrl: string
    ): any {
      state.companyLogoUrl = companyLogoUrl;
    },
    forceReload(state: { forceReload: boolean }, forceReload: boolean): any {
      state.forceReload = forceReload;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    teamIds(state: { teamIds: string[] }): any {
      return state.teamIds;
    },
    teams(state: { teams: any[] }): any {
      return state.teams;
    },
    activeTeamId(state: { activeTeamId: string }): any {
      return state.activeTeamId;
    },
    members(state: { members: any[] }): any {
      return state.members;
    },
    personalTeam(state: { personalTeam: any }): any {
      return state.personalTeam;
    },
    team(state: { team: any }): any {
      return state.team;
    },
    companyLogoUrl(state: { companyLogoUrl: string }): string {
      return state.companyLogoUrl;
    },
    forceReload(state: { forceReload: boolean }): any {
      return state.forceReload;
    },
  },
  actions: {
    async getPersonalTeam(
      {
        commit,
      }: {
        commit: any;
      },
      email: string
    ): Promise<any> {
      if (!email) {
        commit("error", "No email found");
        return;
      }
      const fs = getFirestore();
      const q = query(
        collection(fs, "teams"),
        where("owner", "==", email),
        where("personal", "==", true),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length == 1) {
        const data = querySnapshot.docs[0].data();
        data._id = querySnapshot.docs[0].id;
        commit("personalTeam", data);
      }
    },
    async getTeam(
      {
        commit,
        dispatch,
      }: {
        commit: any;
        dispatch: any;
      },
      { teamId }: { teamId: string }
    ): Promise<any> {
      if (!teamId) {
        commit("error", "No teamId found");
        console.trace();
        return;
      }

      const fs = getFirestore();
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      const data = docSnap.data();
      data._id = docSnap.id;

      if (data.companyLogoUrl) {
        dispatch("applyCompanyLogoUrl", data);
      }

      commit("team", data);
    },
    async createTeam(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      formDetails: {
        name: string;
        copyOptions?: {
          company_and_branding: boolean;
          all_assessments_and_responses: boolean;
        };
        personal?: boolean;
      }
    ): Promise<any> {
      commit("loading", true);

      const now = helpers.now();
      const email = _.get(rootState, "user.user.email");
      const fs = getFirestore();

      const values = {
        name: formDetails.name,
        members: [
          {
            email: email,
            role: "admin", // "admin" | "member
            owner: true,
            active: true,
            created_at: now,
          },
        ],
        member_emails: [email],
        creator: email,
        owner: email,
        personal: formDetails.personal || false,
        deleted: false,
        created_at: now,
      } as any;

      const copyOptions = formDetails.copyOptions;
      if (copyOptions) {
        if (copyOptions.company_and_branding) {
          const user = rootState.user.user;
          values.color = user.color;
          values.companyName = user.companyName;
          values.companyLogoUrl = user.companyLogoUrl || "";
          values.typeOfOrg = user.typeOfOrg || "";
          values.coreValues = user.coreValues || [];
          values.missionAndVision = user.missionAndVision
            ? user.missionAndVision
            : "";
        }
      }

      const document = await addDoc(collection(fs, "teams"), values);
      const newTeamId = document.id;

      // Copy options
      if (copyOptions) {
        let teamToCopyId = "";
        if (
          copyOptions.company_and_branding ||
          copyOptions.all_assessments_and_responses
        ) {
          await dispatch("getPersonalTeam", email);
          const personalTeam = rootState.team.personalTeam;
          if (personalTeam && personalTeam._id) {
            teamToCopyId = personalTeam._id;
          }
        }

        if (!teamToCopyId) {
          commit("error", "No team to copy");
        } else {
          if (copyOptions.all_assessments_and_responses) {
            // Cant' get form "getList" method because user may be not select it yet
            const uuid = "assessment_template#" + email;
            const filter = and(
              where("uuid", "==", uuid),
              where("team_id", "==", teamToCopyId),
              where("deleted", "==", false)
            );
            const q = query(
              collection(fs, "assessment_templates"),
              filter,
              orderBy("created_at", "desc")
            );
            const querySnapshot = await getDocs(q);
            // console.log(querySnapshot.docs.length, "length");
            for (const i in querySnapshot.docs) {
              const doc = querySnapshot.docs[i];
              const data = doc.data();
              const routeId = data.routeId;
              // console.log(data, "data");
              // console.log(routeId, "routeId");
              await dispatch(
                "assessmentTemplate/clone",
                { routeId, teamId: newTeamId, candidatesArecloned: true },
                { root: true }
              );
            }
          }
        }
      }

      commit("activeTeamId", newTeamId);
      commit("loading", false);
    },
    async getTeams({
      commit,
      rootState,
    }: {
      commit: any;
      rootState: any;
    }): Promise<any> {
      const user = rootState.user.user;
      if (!user) {
        commit("error", "user not found");
        return;
      }

      const email = user.email;
      const teams: any[] = [];

      const fs = getFirestore();
      const q = query(
        collection(fs, "teams"),
        // Must be match all conditions
        // where("members", "array-contains", {
        //   email: email,
        //   active: true,
        // })
        where("member_emails", "array-contains", email)
      );
      const querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const document = querySnapshot.docs[i];
        const id = document.id;
        const data = document.data();
        data._id = id;

        // @fixed if user can't see teams, how them can switch to it?

        // const isAdmin = _.find(data.members, { email: email, role: "admin" });
        // if (data.personal || isAdmin) {
        teams.push(data);
        // }
      }
      commit("teams", teams);
    },
    async getTeamIds({
      commit,
      rootState,
    }: {
      commit: any;
      rootState: any;
    }): Promise<any> {
      // console.log("getTeamId()");
      // console.log("rootState", rootState);

      // @todo if user is guest?
      const user = rootState.user.user;
      if (!user) {
        console.log("user not found");
        commit("error", "user not found");
        return;
      }
      const email = user.email;
      // console.log("email", email);

      const fs = getFirestore();
      const q = query(
        collection(fs, "teams"),
        where("member_emails", "array-contains", email)
      );
      const querySnapshot = await getDocs(q);
      // console.log("length", querySnapshot.docs.length);
      const teamIds: string[] = [];
      for (const i in querySnapshot.docs) {
        const document = querySnapshot.docs[i];
        // const data = document.data();
        const teamId = document.id;
        teamIds.push(teamId);
      }
      commit("teamIds", teamIds);
    },
    async getMembers(
      { commit, dispatch }: { commit: any; dispatch: any; getters: any },
      { teamId }: { teamId: string }
    ): Promise<any> {
      commit("loading", true);

      if (!teamId) {
        commit("error", "teamId not found");
        commit("loading", false);
        return;
      }

      const fs = getFirestore();
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      const data = docSnap.data();
      commit("team", data);
      const members = data.members || [];

      // @todo improve performance
      for (const i in members) {
        const member = members[i];
        const user = await dispatch("user/getUser", member.email, {
          root: true,
        });
        member.active = _.isObject(user);
        member.displayed_name = member.email;
        if (member.active) {
          member.displayed_name = _.get(user, "displayName");
        }
      }

      commit("members", members);
      commit("loading", false);
    },
    async addMember(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      { teamId, email }: { teamId: string; email: string }
    ): Promise<any> {
      commit("loading", true);

      if (!teamId) {
        commit("error", "teamId not found");
        commit("loading", false);
        return;
      }

      // prevent `passed by reference` in javascript to call objects at list to manage status
      await dispatch(
        "team/getMembers",
        { teamId: teamId },
        {
          root: true,
        }
      );
      const members = _.cloneDeep(rootState.team.members);
      members.push({
        active: true,
        created_at: helpers.now(),
        email: email,
        owner: false,
        role: "member",
        invited: false,
        sendmail: false,
      });

      const memberEmails = _.map(members, (member: any) => {
        return member.email;
      });

      // @see active on click link in email

      // @todo if user is arready user but not in team

      // const member_emails = _.uniq(
      //   members.map((member: { email: string }) => {
      //     return member.email;
      //   })
      // );

      const fs = getFirestore();
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      const baseUrl = `${window.location.protocol}//${window.location.host}`;

      await setDoc(
        doc(fs, "teams", teamId),
        {
          members,
          member_emails: memberEmails,
          last_action_url: baseUrl,
          last_actor: rootState.user.user.email,
        },
        { merge: true }
      );
    },
    async removeMember(
      {
        commit,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      { teamId, email }: { teamId: string; email: string }
    ): Promise<any> {
      commit("loading", true);

      if (!teamId) {
        commit("error", "teamId not found");
        commit("loading", false);
        return;
      }
      const members = rootState.team.members;
      // const memberEmails = rootState.team.member_emails;
      const newMembers = [];
      // const newMemberEmails = [];
      for (const i in members) {
        if (members[i].email !== email) {
          newMembers.push(members[i]);
        }
      }

      const newMemberEmails = _.map(newMembers, "email");

      const fs = getFirestore();
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      await setDoc(
        doc(fs, "teams", teamId),
        {
          member_emails: newMemberEmails,
          members: newMembers,
        },
        { merge: true }
      );
    },
    async changeRole(
      {
        commit,
        getters,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      { teamId, email, role }: { teamId: string; email: string; role: string }
    ): Promise<any> {
      const members = _.cloneDeep(getters.members);
      for (const i in members) {
        const member = members[i];
        if (member.email === email) {
          member.role = role;
        }
      }

      const fs = getFirestore();
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      await setDoc(
        doc(fs, "teams", teamId),
        {
          members,
        },
        { merge: true }
      );
    },
    async applyCompanyLogoUrl(
      { commit, dispatch }: { commit: any; dispatch: any },
      { companyLogoUrl }: { companyLogoUrl?: string }
    ): Promise<any> {
      companyLogoUrl = String(companyLogoUrl);
      const variableName = "companyLogoUrl";
      if (companyLogoUrl.indexOf("https://") === 0) {
        commit(variableName, companyLogoUrl);
      } else {
        dispatch(
          "team/downloadImageUrl",
          {
            imagePath: companyLogoUrl,
            variableName: variableName,
          },
          { root: true }
        );
      }
    },
    downloadImageUrl(
      { commit }: { commit: any },
      { imagePath, variableName }: { imagePath: string; variableName: string }
    ): any {
      const storage = getStorage();
      const $ref = storageRef(storage, imagePath);
      getDownloadURL($ref)
        .then((url) => {
          commit(variableName, url);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    async saveTeam(
      { commit }: { commit: any; dispatch: any },
      formDetails: { _id: string }
    ): Promise<any> {
      commit("loading", true);
      const teamId = formDetails._id;
      const values: any = _.omit(formDetails, ["_id"]);
      values.updated_at = helpers.now();

      const fs = getFirestore();
      const docRef = doc(fs, "teams", teamId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "No such document!");
        return;
      }

      await setDoc(doc(fs, "teams", teamId), values, { merge: true });

      commit("loading", false);
    },
    async setup(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      user: { email: string; teamId?: string }
    ): Promise<any> {
      // psuedo code
      //
      // 1. check flag to setup
      // 2. create personal team
      // 3. assign team id to all assessments
      // 4. assign team id to user (realttime databaase)
      const email = user.email;

      const fs = getFirestore();
      let q;
      let querySnapshot;

      // 1. check flag to setup
      if (user.teamId) {
        // console.log("User already setup personal team");
        return;
      }

      // 2. create personal team
      let teamId = "";
      q = query(
        collection(fs, "teams"),
        where("owner", "==", email),
        where("personal", "==", true),
        limit(1)
      );
      querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length == 1) {
        teamId = querySnapshot.docs[0].id;
        commit("team/activeTeamId", teamId, { root: true });
      } else {
        const formDetails = {
          name: "Personal",
          personal: true,
        };
        await dispatch("createTeam", formDetails);
        teamId = rootState.team.activeTeamId;
      }

      // 3. assign team id to all assessments
      const uuid = "assessment_template#" + email;
      const filter = and(
        where("uuid", "==", uuid),
        where("deleted", "==", false)
      );
      // const fs = getFirestore();
      q = query(
        collection(fs, "assessment_templates"),
        filter,
        orderBy("created_at", "desc")
      );
      querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const templateId = querySnapshot.docs[i].id;
        await setDoc(
          doc(fs, "assessment_templates", templateId),
          {
            team_id: teamId,
            scope: "personal",
          },
          { merge: true }
        );
      }

      // 4. assign team id to user (realttime databaase)
      user.teamId = teamId;
      await dispatch("user/saveUser", user, { root: true });
    },
    async observeTeam(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { teamId }: { teamId: string }
    ): Promise<any> {
      // console.log(`observeTeam ${teamId}`);

      const fs = getFirestore();
      onSnapshot(doc(fs, "teams", teamId), async (doc) => {
        // If user was removed form the current team
        const team = doc.data();
        if (!team) return;

        const user = rootState.user.user;
        const email = user.email;
        if (!email) {
          commit("error", "No email found");
          return false;
        }

        if (user.autoGenerateForm) {
          // do not interupt autoGenerateForm
          return;
        }

        const memberEamils = _.get(team, "member_emails", []);
        const found = memberEamils.indexOf(user.email) !== -1;
        if (!found) {
          // force update user.teamId to personal
          await dispatch("getPersonalTeam", email);
          const personalTeam = rootState.team.personalTeam;
          if (personalTeam && personalTeam._id) {
            await dispatch("user/changeTeam", personalTeam._id, { root: true });
            commit("forceReload", true);
            commit(
              "error",
              helpers.t("myAccount.You_have_been_removed_from_the_team")
            );
          }
        }
      });
    },
    async deleteTeam(
      { commit }: { commit: any; dispatch: any; rootState: any },
      { teamId }: { teamId: string }
    ): Promise<any> {
      commit("loading", true);

      const fs = getFirestore();
      await deleteDoc(doc(fs, "teams", teamId));

      commit("loading", false);
    },
  },
};
