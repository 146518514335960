import {
  getFirestore,
  collection,
  query,
  where,
  limit,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import helpers from "@/helpers/global";
import {
  ref,
  get,
  query as $query,
  orderByChild,
  equalTo,
  getDatabase,
} from "firebase/database";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    error: null,
    data: null,
    loading: false,
    lastInsertId: "",
    publicLink: "",
    invitedLink: "",
    referrals: null,
  },
  mutations: {
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    error(state: { error: string }, error: string): any {
      state.error = error;
    },
    data(state: { data: any }, data: { uuid: string }): any {
      state.data = data;
    },
    lastInsertId(state: { lastInsertId: string }, lastInsertId: string): any {
      state.lastInsertId = lastInsertId;
    },
    publicLink(state: { publicLink: string }, publicLink: string): any {
      state.publicLink = publicLink;
    },
    invitedLink(state: { invitedLink: string }, invitedLink: string): any {
      state.invitedLink = invitedLink;
    },
    referrals(state: { referrals: any }, referrals: any[]): any {
      state.referrals = referrals;
    },
  },
  getters: {
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    error(state: { error: string }): any {
      return state.error;
    },
    lastInsertId(state: { lastInsertId: string }): any {
      return state.lastInsertId;
    },
    publicLink(state: { publicLink: string }): any {
      return state.publicLink;
    },
    invitedLink(state: { invitedLink: string }): any {
      return state.invitedLink;
    },
    referrals(state: { referrals: any }): any {
      return state.referrals;
    },
    data(state: { data: any }): any {
      return state.data;
    },
  },
  actions: {
    async invite(
      { commit, rootState }: { commit: any; dispatch: any; rootState: any },
      { email }: { email: string }
    ): Promise<any> {
      commit("loading", true);
      const emailKey = helpers.emailKey(email);
      const path = `users/${emailKey}`;
      const user = (await get($query(ref(getDatabase(), path)))).val();
      if (user) {
        commit("error", `${email} already uses MyCulture`);
        return;
      }

      const teamId = _.get(rootState, "user.user.teamId");
      if (!teamId) {
        commit("error", "No teamId");
        return;
      }

      const referer = rootState.auth.user.email;
      const owner = email;
      const uuid =
        `referral-` +
        teamId +
        "-" +
        helpers.emailKey(referer) +
        "-" +
        helpers.emailKey(owner);

      const fs = getFirestore();
      const q = query(collection(fs, "routes"), where("uuid", "==", uuid));
      const querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const snapshot = querySnapshot.docs[i];
        deleteDoc(doc(fs, "routes", snapshot.id));
      }

      const fn = helpers.generateString;
      let notFound = true;
      let id = "";
      do {
        id = fn(35);
        const docRef = doc(fs, "routes", id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          const path = "l/:referralCode";
          const routeDetails = {
            path: path,
            uuid: uuid,
            owner: email,
            teamId: teamId,
            referer: referer,
            data: {
              invitedLink: window.location.origin + "/l/" + id,
            },
          };
          await setDoc(doc(fs, "routes", id), {
            ...routeDetails,
            created_at: helpers.now(),
          });
          commit("lastInsertId", id);
          const invitedLink = path.replace(":referralCode", id);
          commit("invitedLink", invitedLink);
          notFound = false;
        }
      } while (notFound);

      commit("loading", false);
    },
    async createPublicLink(
      { commit }: { commit: any },
      { email, teamId }: { email: string; teamId: string }
    ): Promise<any> {
      // console.log(`createPublicLink(${email}, ${teamId})`);
      // if (email) return;
      const emailKey = helpers.emailKey(email);
      const uuid = "referral-" + emailKey;
      const fs = getFirestore();

      const fn = helpers.generateString;
      let notFound = true;
      let id = "";
      do {
        id = fn(3) + "-" + fn(3) + "-" + fn(3);
        const docRef = doc(fs, "routes", id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          const path = "join?:referralCode";
          const routeDetails = {
            path: path,
            uuid: uuid,
            owner: email,
            teamId: teamId,
          };
          await setDoc(doc(fs, "routes", id), {
            ...routeDetails,
            created_at: helpers.now(),
          });
          commit("lastInsertId", id);
          const publicLink = path.replace(":referralCode", id);
          commit("publicLink", publicLink);
          notFound = false;
        }
      } while (notFound);
    },
    // get public link for each user (lazy load, auto create)
    async getPublicLink(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { email }: { email: string }
    ): Promise<any> {
      const emailKey = helpers.emailKey(email);
      const uuid = "referral-" + emailKey;

      // console.log(rootState.user.user, "rootState.user.user");
      const teamId = _.get(rootState, "user.user.teamId");
      let isPersonal = true;
      if (teamId) {
        await dispatch("team/getTeam", { teamId: teamId }, { root: true });
        const team = rootState.team.team;
        if (team) {
          isPersonal = team.personal || false;
        }
      } else {
        commit("error", "No teamId");
        return;
      }

      const fs = getFirestore();
      const q = query(
        collection(fs, "routes"),
        where("uuid", "==", uuid),
        where("teamId", "==", teamId),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.size) {
        for (const i in querySnapshot.docs) {
          const snapshot = querySnapshot.docs[i];
          const id = snapshot.id;
          const data = snapshot.data();

          // Compatability with old data
          if (!isPersonal && !data.teamId) {
            await dispatch("createPublicLink", { email, teamId });
          } else {
            const publicLink = data.path.replace(":referralCode", id);
            commit("publicLink", publicLink);
          }
        }
      } else {
        await dispatch("createPublicLink", { email, teamId });
      }
    },
    async validateReferralLink(
      { commit }: { commit: any },
      id: string
    ): Promise<any> {
      const fs = getFirestore();
      const docRef = doc(fs, "routes", id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        commit("error", "Unknown referral link.");
      } else {
        commit("data", docSnap.data());
        commit("error", "");
      }
    },
    async getReferrals(
      { commit }: { commit: any },
      { email }: { email: string }
    ): Promise<any> {
      const db = getDatabase();
      const path = "users";
      const snapshots = await get(
        $query(ref(db, path), orderByChild("referer"), equalTo(email))
      );
      const referrals: any = [];
      snapshots.forEach((child) => {
        if (child.exists()) {
          const value = child.val();
          referrals.push({ ...value, _key: child.key });
        }
      });
      commit("referrals", _.sortBy(referrals, "createdAt").reverse());
    },
  },
};
