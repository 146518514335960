import { getFirestore, getDoc, setDoc, doc } from "firebase/firestore";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    assessmentSections: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    assessmentSections(
      state: { assessmentSections: any },
      assessmentSections: {
        values_and_traits: any;
        oaic_archetypes: any;
        acceptable_behaviors: any;
        work_style_identifier: any;
      }
    ): any {
      // console.log("assessmentSections()");
      // console.log(assessmentSections, "assessmentSections");
      state.assessmentSections = assessmentSections;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    assessmentSections(state: { assessmentSections: any }): any {
      return state.assessmentSections;
    },
  },
  actions: {
    async load({ commit }: { commit: any }, name: string): Promise<any> {
      // console.log(`load(${name})`);
      commit("loading", true);
      const $db = getFirestore();
      const docRef = doc($db, "caches", name);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        commit(_.camelCase("assessment_sections"), data);
      }
      commit("loading", false);
    },
    async set(
      { commit }: { commit: any },
      { name, values }: { name: string; values: any }
    ): Promise<any> {
      // console.log(`set(${name})`);
      // console.log(values, "values");
      commit("loading", true);
      const $db = getFirestore();

      // prevent save large object
      // const newValues: any = {};
      // for (const i in values) {
      //   newValues[i] = JSON.stringify(values[i]);
      // }
      await setDoc(doc($db, "caches", name), values, { merge: true });
      commit("loading", false);
    },
  },
};
