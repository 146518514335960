import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  query,
  getDocs,
  collection,
  where,
  limit,
} from "firebase/firestore";

import helpers from "@/helpers/global";
import _ from "lodash";

// Collection Name
const CN = "caches";

const FORCE_QUERY_OPENAPI = true;
const OPENAI_OPT_STREAM = true;

// const OPENAI_MODEL_DEFAULT = "gpt-4";
// const OPENAI_MODEL_DEFAULT = "gpt-3.5-turbo";
// const OPENAI_MODEL_DEFAULT = "gpt-4-turbo-preview";
const OPENAI_MODEL_DEFAULT = "gpt-4o";

const OPENAI_ENDPOINT = "https://api.openai.com/v1/chat/completions";

const DEFAULT_PARAMS = {
  model: OPENAI_MODEL_DEFAULT,

  temperature: 0.01,
  max_tokens: 1024,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,

  stream: OPENAI_OPT_STREAM,
};

// const PROMPT_ROLE_SYSTEM = "";

const PROMPT_JOB_ROLE = `You're an expert at crafting job posts for job boards and LinkedIn that express the company's character and values, writing copy and descriptions that are clear and stand out. The goal is to convert high-quality candidates.\n\nThink about the information structure and content that would impress the reader. Use language that is easy to understand. No over-promising, and ensure the core values are clearly expressed throughout. Include:\n- An About Us section\n- Role Overview\n- Key Responsibilities with 6-8 essential items, each detailed with tangible examples where applicable\n- Qualifications (highlight skills that are required to be successful in this role)\n- 30/60/90 Day Success Plan and Roadmap\n- Dealbreakers\n- Why Join?\n- Call-to-Action\nFor information that is missing to create a complete job post, use clearly marked placeholders that make it easy to see and edit. Formatting Guidelines: Do not include the job title label at the top, remove the paragraph spacing between the section headers and text, and format the text using markdown.\n\nThe company is a <%= TypeOfOrganization %> called <%= CompanyName %>. The company's values are: <%= coreValues %>\n\nCreate a job post for the <%= Role %> role at the <%= Level %> level.`;

const request = async function (params: { messages: any[] }) {
  const params_: any = { ...DEFAULT_PARAMS, ...params };

  const endpoint = OPENAI_ENDPOINT;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
    },
    body: JSON.stringify(params_),
  };

  const response = await fetch(endpoint, requestOptions);
  const data = await response.json();
  return data.choices[0].message.content;
};

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    result: null,
    title: "",
    subtitle: "",
    isTyping: false,
    locked: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    result(state: { result: string }, result: string): any {
      state.result = result;
    },
    title(state: { title: string }, title: string): any {
      state.title = title;
    },
    subtitle(state: { subtitle: string }, subtitle: string): any {
      state.subtitle = subtitle;
    },
    isTyping(state: { isTyping: boolean }, isTyping: boolean): any {
      state.isTyping = isTyping;
    },
    locked(state: { locked: boolean }, locked: boolean): any {
      state.locked = locked;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    result(state: { result: string }): any {
      return state.result;
    },
    title(state: { title: string }): any {
      return state.title;
    },
    subtitle(state: { subtitle: string }): any {
      return state.subtitle;
    },
    isTyping(state: { isTyping: boolean }): any {
      return state.isTyping;
    },
    locked(state: { locked: boolean }): any {
      return state.locked;
    },
  },
  actions: {
    async generate(
      {
        commit,
        dispatch,
        rootState,
      }: {
        commit: any;
        dispatch: any;
        rootState: any;
      },
      {
        role,
        level,
        typeOfOrg,
        coreValues,
      }: {
        role: string;
        level: string;
        typeOfOrg: string;
        coreValues: string[];
      }
    ): Promise<any> {
      const credits = 1;

      const quotaData = rootState.quota.data;
      if (!quotaData.unlimited) {
        if (quotaData.quota_remaining < credits) {
          commit("error", "Not enough quota");
          return;
        }
      }

      commit("result", null);
      commit("loading", true);
      commit("locked", true);

      let $coreValues = "";
      if (coreValues.length) {
        if (coreValues.length > 1) {
          const _coreValues = _.clone(coreValues);
          const last = _coreValues.pop();
          $coreValues = _coreValues.join(", ") + " and " + last;
        } else {
          $coreValues = coreValues[0];
        }
      }

      const variables = {
        CompanyName: _.get(rootState, "user.user.companyName"),
        Role: role,
        Level: level,
        TypeOfOrganization: typeOfOrg,
        coreValues: $coreValues,
      };
      commit("title", `${role} ${level}`);
      commit("subtitle", coreValues.join(" • "));

      let result;
      let cacheId: any;
      let cacheDetails: any;
      let responseText: string;
      let error;

      const cachePrefix = "prompt_jobpostgenerator";
      const uuid =
        cachePrefix + "=" + new URLSearchParams(variables).toString();

      const fs = getFirestore();
      const q = query(collection(fs, CN), where("uuid", "==", uuid), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc: any) => {
        cacheId = String(doc.id);
        cacheDetails = doc.data();
        result = cacheDetails.data;
      });

      const writeCache = async (
        params: any,
        _responseText: string,
        result: string
      ) => {
        const idPrefix = `${cachePrefix}-`;
        let notFound = true;
        let id = "";
        do {
          id = idPrefix + helpers.generateString(32);
          const docRef = doc(fs, CN, id);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            cacheDetails = {
              scope: cachePrefix,
              params: params,
              responseText: _responseText,
              data: result,
              uuid: uuid,
              created_at: helpers.now(),
            };
            cacheId = id;
            notFound = false;
          }
        } while (notFound);

        if (cacheDetails) {
          setDoc(doc(fs, CN, cacheId), cacheDetails, {
            merge: true,
          });
        }
      };

      // use credit before use openai becauset it's stream
      const teamId = rootState.user.user.teamId;
      await dispatch(
        "quota/useCredits",
        { teamId, credits: credits, scope: cachePrefix, variables: variables },
        { root: true }
      );

      if (!cacheId || FORCE_QUERY_OPENAPI) {
        const params = {
          messages: [] as any,
        };
        const content = _.template(PROMPT_JOB_ROLE)(variables);
        if (content.length) {
          // params.messages.push({ role: "system", content: PROMPT_ROLE_SYSTEM });
          params.messages.push({ role: "user", content: content });
        }

        //*
        try {
          if (OPENAI_OPT_STREAM) {
            const params_: any = { ...DEFAULT_PARAMS, ...params };
            const endpoint = OPENAI_ENDPOINT;
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + String(process.env.VUE_APP_OPENAI_API_KEY),
              },
              body: JSON.stringify(params_),
            };
            fetch(endpoint, requestOptions)
              .then((response: any) => {
                const reader = response.body.getReader();
                let resonseText = "";
                commit("loading", false);
                commit("isTyping", true);
                reader.read().then(function pump({ done, value }: any) {
                  if (done) {
                    // Do something with last chunk of data then exit reader
                    writeCache(params, resonseText, resonseText);
                    commit("isTyping", false);
                    commit("locked", false);
                    return;
                  }
                  // Otherwise do something here to process current chunk
                  const text = new TextDecoder().decode(value);
                  const prefix = "data: ";
                  const pos = text.indexOf(prefix);
                  if (pos === 0) {
                    const lines = text.split("\n");
                    for (const i in lines) {
                      if (!lines[i]) continue;
                      if (lines[i].indexOf(prefix) === 0) {
                        const v = lines[i].substring(prefix.length);
                        if (v === "[DONE]") continue;
                        const value = JSON.parse(v);
                        if (_.isObject(value)) {
                          const content = _.get(
                            value,
                            "choices[0].delta.content"
                          );
                          if (content) {
                            resonseText += String(content);
                          }
                          commit("result", resonseText);
                          // console.log(resonseText, "resonseText");
                        }
                      }
                    }
                  }
                  // Read some more, and call this function again
                  return reader.read().then(pump);
                });
              })
              .catch((e: unknown) => {
                error = true;
                if (e instanceof Error) {
                  commit("error", e.message);
                }
                console.error(e);
              });
          } else {
            responseText = await request(params);
            result = responseText;
            writeCache(params, responseText, result);
            commit("locked", false);
          }
          error = false;
        } catch (e: unknown) {
          error = true;
          if (e instanceof Error) {
            error && commit("error", e.message);
          }
          console.error(e);
        }
      } else {
        commit("locked", false);
      }

      /*
      if (result) {
        commit("isTyping", true)
        const lines = result.split("\n");
        let text = "";
        for (const i in lines) {
          setTimeout(() => {
            text += lines[i] + "\n";
            commit("result", text);
            if (Number(i) + 1 == lines.length) {
              commit("isTyping", false);
            }
          }, Number(i) * 500);
        }
      }
      //*/

      await dispatch("quota/load", rootState.user.user, { root: true });

      commit("result", result);
      commit("loading", false);
    },
  },
};
