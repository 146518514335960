/* eslint-disable prettier/prettier */
import { getDatabase, ref, query, get, set, update } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import format from "date-fns/format";
import helpers from "@/helpers/global";
import _ from "lodash";
import AppSettings, { UserDetails } from "../types";
import { ElMessage } from 'element-plus'

import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
interface State {
  error: string;
  loading: boolean;
  user?: any;
  companyLogoUrl?: any;
}

interface R {
  commit?: any;
  dispatch?: any;
  getters?: any;
  rootState?: any;
}

interface User {
  id: string;
  email: string;
  companyLogoUrl?: string;
  lang?: string;
}

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    user: null,
    companyLogoUrl: null,
  },
  mutations: {
    error(state: State, error: string): any {
      state.error = error;
    },
    loading(state: State, loading: boolean): any {
      state.loading = loading;
    },
    user(state: State, user: User): any {
      state.user = user;
    },
    companyLogoUrl(state: State, companyLogoUrl: string): any {
      state.companyLogoUrl = companyLogoUrl;
    },
  },
  getters: {
    error(state: State): string {
      return state.error;
    },
    loading(state: State): boolean {
      return state.loading;
    },
    user(state: State): User {
      return state.user;
    },
    companyLogoUrl(state: State): string {
      return state.companyLogoUrl;
    },
  },
  actions: {
    // uploadAvatar({ commit }: { commit: any },): any {
    // console.log(res.file, "res.file");
    // console.log(this.form.imageFile, "this.form.imageFile");
    // // const app = getApp();
    // const storage = getStorage();
    // console.log(storage, "storage");
    // const email = helpers.emailKey(this.form.email);
    // const storageRef = ref(
    //   storage,
    //   `images/users/${email}/${res.file.uid}-${res.file.name}`
    // );
    // uploadString(storageRef, this.form.imageFile, "data_url").then(
    //   (snapshot) => {
    //     console.log(snapshot, "snapshot");
    //   }
    // );
    // upload by file
    // const uploadTask = uploadBytesResumable(storageRef, res.file);
    // uploadTask.on("state_changed", (snapshot) => {
    //   console.log(snapshot, "snapshot");
    // });
    // },

    async getUser({ commit }: R, email: string): Promise<any> {
      const emailKey = helpers.filterPath(email);
      const db = getDatabase();
      const path = `users/${emailKey}`;
      const user = (await get(query(ref(db, path)))).val();
      commit("error", null);
      return user;
    },

    async hasUser({ commit, dispatch }: R, email: string): Promise<any> {
      // const emailKey = helpers.filterPath(email);
      // const db = getDatabase();
      // const path = `users/${emailKey}`;
      // const user = (await get(query(ref(db, path)))).val();
      const user = await dispatch("getUser", email);
      commit("error", null);
      return !_.isEmpty(user);
    },

    async createUser(
      { commit }: R,
      userDetails: UserDetails
    ): Promise<any> {
      // @todo filter
      // @todo validate

      //*
      const email = userDetails.email;
      const emailKey = helpers.filterPath(email);
      const db = getDatabase();
      const path = `users/${emailKey}`;
      const user = (await get(query(ref(db, path)))).val();
      if (user) {
        const error = `this email (${email}) has already been registered`;
        commit("error", error);
        console.error(error);
        return;
      }

      // console.log("creating a user ...");
      // set user
      const values = _.assign(
        {
          email: email,
          displayName: `${userDetails.firstName} ${userDetails.lastName}`,
          photoURL: "",
          createdAt: format(new Date(), AppSettings.DATETIME_FORMAT),
        },
        userDetails
      );

      set(ref(db, "users/" + emailKey), values)
        .then(() => {
          // Data saved successfully!
          // commit("error", false);
          const password = email;

          const auth = getAuth();
          createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              // console.log(userCredential, "userCredential");
              // Signed in
              const user = userCredential.user;
              if (!user) {
                commit("error", "Can't create user.");
              }

              commit("user", values);

              // background process with cloud function
              // await dispatch(
              //   "quota/createDefaultQuota",
              //   { email: email },
              //   { root: true }
              // );
            })
            .catch((e) => {
              // const errorCode = error.code;
              const error = e.message;
              // ..
              // console.log(errorCode, "errorCode");
              console.log(error, "error");
              commit("error", error);
            });
        })
        .catch((error) => {
          // The write failed...
          console.log(error, "error");
          commit("error", error.message);
        });

      // @todo commit user
      // commit("error", null);
      //*/
    },
    downloadImageUrl({ commit }: R, {imagePath, variableName}: {imagePath: string; variableName: string}): any {
      const storage = getStorage();
      const $ref = storageRef(storage, imagePath);
      getDownloadURL($ref)
        .then((url) => {
          commit(variableName, url);
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    async applyCompanyLogoUrl(
      { commit, dispatch }: R,
      { companyLogoUrl }: {companyLogoUrl?: string}
    ): Promise<any> {
      companyLogoUrl = String(companyLogoUrl);
      const variableName = "companyLogoUrl";
      if (companyLogoUrl.indexOf("https://") === 0) {
        commit(variableName, companyLogoUrl);
      } else {
        dispatch("downloadImageUrl", {imagePath: companyLogoUrl, variableName: variableName});
      }
    },
    async loadCurrentUser({ commit, dispatch }: R): Promise<any> {
      commit("loading", true);
      const userDataEncoded: any = localStorage.getItem("userData");
      // console.log(userDataEncoded, "userDataEncoded");
      if (userDataEncoded) {
        try {
          const userData = JSON.parse(atob(userDataEncoded) || "{}");
          const email  = userData.email || "";
          const emailKey = helpers.filterPath(email);

          const db = getDatabase();
          const path = `users/${emailKey}`;
          const user = (await get(query(ref(db, path)))).val();
          if (!user) {
            setTimeout(async () => {
              await dispatch("auth/logout", null, { root: true });
              window.location.href = "/login";
            }, 3000);
            throw new Error(`Invalid user ${email}`);
          }
          if (user.companyLogoUrl) {
            dispatch("applyCompanyLogoUrl", user);
          }
          commit("user", user);

          // aut load team
          if (user && user.teamId) {
            await dispatch(
              "team/getTeam",
              { teamId: user.teamId },
              { root: true }
            );
          }

          dispatch("forceReady", null, { root: true });
        } catch (e: any) {
          console.log(e, "e");
          commit("error", e.message);
        }
      }

      commit("loading", false);
    },
    async saveUser({ commit, dispatch }: R, user: User): Promise<any> {
      commit("loading", true);
      const now = helpers.now();
      const emailKey = helpers.filterPath(user.email);
      const userValues = {...user, updatedAt: now};
      const db = getDatabase();
      const path = `users/${emailKey}`;
      update(ref(db, path), userValues)
      .then(() => {
        // Data saved successfully!
        if (user.companyLogoUrl) {
          dispatch("applyCompanyLogoUrl", user);
        }
        commit("error", false);
        commit("loading", false);
      })
      .catch((error) => {
        // The write failed...
        console.error(error);
        commit("error", error.message);
        commit("loading", false);
        ElMessage({
          message: "Failed, We can't update user detail!",
          type: "success",
        });
      });
    },
    async changeTeam({ dispatch, getters }: R, teamId: string): Promise<any> {
      const user = getters.user;
      user.teamId = teamId;
      await dispatch("saveUser", user);
    },
  }
}