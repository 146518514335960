import router from "@/router";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  jopTitle: string;
  hiresPerYears: string;
  // cultureDevelopment: string;
  color: string;
  imageFile: string;
  acceptTerrms: boolean;
  sendFreeStuff: boolean;
  companyUploadedFile: {
    readonly lastModified: number;
    readonly name: string;
    readonly webkitRelativePath: string;
    readonly size: number;
    readonly type: string;
    arrayBuffer: {
      (): Promise<ArrayBuffer>;
      (): Promise<ArrayBuffer>;
    };
    slice: {
      (
        start?: number | undefined,
        end?: number | undefined,
        contentType?: string | undefined
      ): Blob;
      (
        start?: number | undefined,
        end?: number | undefined,
        contentType?: string | undefined
      ): Blob;
    };
    // stream: { (): ReadableStream<any>; (): NodeJS.ReadableStream };
    text: { (): Promise<string>; (): Promise<string> };
  };
  companyUploadedFileDataUrl: string;
  companyLogoUrl: string;
}

interface UserCredentialImpl {
  operationType: string;
  providerId: string;
  user?: any;
  _tokenResponse?: any;
}

export default {
  namespaced: true,
  state: {
    error: false,
    createStep: "create_email",
    formValues: {
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      jopTitle: "",
      hiresPerYears: "",
      // cultureDevelopment: "",
      color: "#1B1C1E",
      imageFile: "",
      acceptTerrms: true,
      sendFreeStuff: true,
      companyUploadedFile: new File([], ""),
      companyUploadedFileDataUrl: "",
      companyLogoUrl: "",
    },
    oAuthProvider: null,
    oAuthResult: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    createStep(state: { createStep: string }, createStep: string): any {
      state.createStep = createStep;
    },
    email(state: { formValues: { email: string } }, email: string): any {
      state.formValues.email = email;
    },
    firstName(
      state: { formValues: { firstName: string } },
      firstName: string
    ): any {
      state.formValues.firstName = firstName;
    },
    lastName(
      state: { formValues: { lastName: string } },
      lastName: string
    ): any {
      state.formValues.lastName = lastName;
    },
    oAuthProvider(
      state: { oAuthProvider: string },
      oAuthProvider: string
    ): any {
      state.oAuthProvider = oAuthProvider;
    },
    oAuthResult(
      state: { oAuthResult: any },
      oAuthResult: UserCredentialImpl
    ): any {
      state.oAuthResult = oAuthResult;
    },
  },
  getters: {
    error(state: { error: boolean }): boolean {
      return state.error;
    },
    formValues(state: { formValues: any }): any {
      return state.formValues;
    },
    createStep(state: { createStep: string }): string {
      return state.createStep;
    },
    oAuthProvider(state: { oAuthProvider: string }): string {
      return state.oAuthProvider;
    },
    oAuthResult(state: { oAuthResult: string }): string {
      return state.oAuthResult;
    },
  },
  actions: {
    setCreateStep({ commit }: { commit: any }, createStep: string): any {
      commit("createStep", createStep);

      // new feature: signup stepper
      router.push({ name: "SignupInfo" });
    },
    setFormValues({ commit }: { commit: any }, formValues: FormValues): any {
      commit("formValues", formValues);
    },
    setEmail({ commit }: { commit: any }, email: string): any {
      commit("email", email);
    },
    setFirstName({ commit }: { commit: any }, firstName: string): any {
      commit("firstName", firstName);
    },
    setLastName({ commit }: { commit: any }, lastName: string): any {
      commit("lastName", lastName);
    },
    setOAuthProvider({ commit }: { commit: any }, oAuthProvider: string): any {
      commit("oAuthProvider", oAuthProvider);
    },
    setOAuthResult(
      { commit, state }: { commit: any; state: any },
      oAuthResult: UserCredentialImpl
    ): any {
      if (state.oAuthProvider == "google") {
        const email = oAuthResult.user.email;
        const displayName = oAuthResult.user.displayName;
        const firstName = displayName.split(" ")[0];
        const lastName = displayName.split(" ")[1];
        commit("email", email);
        commit("firstName", firstName);
        commit("lastName", lastName);
      } else if (state.oAuthProvider == "microsoft") {
        let email = String(oAuthResult.user.email);

        // @fixed hotmail
        // @note email: rcoco66_hotmail.com#ext#@rcoco66hotmail.onmicrosoft.com
        if (email.indexOf("#") !== -1) {
          email = email.split("#")[0];
          email = email.replace("_hotmail.com", "@hotmail.com");
        }

        const displayName = oAuthResult.user.displayName;
        const firstName = displayName.split(" ")[0];
        const lastName = displayName.split(" ")[1];
        commit("email", email);
        commit("firstName", firstName);
        commit("lastName", lastName);
      }

      commit("oAuthResult", oAuthResult);
    },
    setOAuthResultDetails(
      { dispatch }: { dispatch: any },
      oAuthResultDetails: {
        oAuthProvider: string;
        oAuthResult: UserCredentialImpl;
      }
    ): any {
      dispatch("setOAuthProvider", oAuthResultDetails.oAuthProvider);
      dispatch("setOAuthResult", oAuthResultDetails.oAuthResult);
    },
  },
};
