import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  doc,
  getDocs,
  addDoc,
  setDoc,
} from "firebase/firestore";
import {
  getDatabase,
  ref,
  query as $query,
  get,
  update,
} from "firebase/database";

import _ from "lodash";
import helpers from "@/helpers/global";

import asssessmentTemplateData from "./data/assessmentTemplate.json";
import asssessmentsData from "./data/assessments.json";
import routeData from "./data/route.json";

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
  },
  actions: {
    check({ commit, rootState }: { commit: any; rootState: any }): boolean {
      const user = rootState.auth.user;
      if (!user) {
        commit(
          "auth/errorMessage",
          "You are not logged in. Please login and try again.",
          { root: true }
        );
        return false;
      }
      return true;
    },
    async createAssessmentTemplate({
      dispatch,
      rootState,
    }: {
      dispatch: any;
      rootState: any;
    }): Promise<any> {
      if (!dispatch("check")) return;

      const user = rootState.auth.user;
      const email = helpers.filterEmail(user.email);

      // Only first time
      const emailKey = helpers.emailKey(email);
      const db = getDatabase();
      const path = `users/${emailKey}`;
      const userData = (await get($query(ref(db, path)))).val();
      if (userData.hasSampleData) return;

      // Must be empty table too
      const uuid = "assessment_template#" + email;
      const fs = getFirestore();
      const q = query(
        collection(fs, "assessment_templates"),
        where("uuid", "==", uuid),
        where("deleted", "==", false),
        orderBy("created_at", "desc")
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length) return;

      // create from JSON instead read from firestore for all environment (dev, staging and production)
      // @see "assessment_templates/ugBhsx0fZwHMHHs8HjUo";
      // console.log(asssessmentTemplateData, "asssessmentTemplateData");
      if (!asssessmentTemplateData) {
        console.error("Sample assessment template data was not found.");
        return;
      }

      const formDetails: any = _.omit(asssessmentTemplateData, ["updated_at"]);

      // Update new public route URL
      // const origRouteId = formDetails.routeId;
      // await dispatch("routes/getRoute", origRouteId, { root: true });
      // const routsDetails = _.cloneDeep(rootState.routes.data);
      const routsDetails = _.cloneDeep(routeData);
      routsDetails.data.creator = email;
      routsDetails.data.email = email;

      await dispatch("routes/create", routsDetails, {
        root: true,
      });
      const newRouteId = rootState.routes.lastInsertId;
      formDetails.routeId = newRouteId;

      // children are completed assessments for each candidate
      // we must pull all cadidates
      // ugBhsx0fZwHMHHs8HjUo-tareef@happily||ai : tareef@happily.ai	harlee@acme.com
      // ugBhsx0fZwHMHHs8HjUo-narges@happily||ai : narges@happily.ai	ivan@acme.com
      // ugBhsx0fZwHMHHs8HjUo-bank@happily||ai : bank@happily.ai	jensen@acme.com
      // ugBhsx0fZwHMHHs8HjUo-feen@happily||ai : feen@happily.ai	kirsten@acme.com
      // ugBhsx0fZwHMHHs8HjUo-fah@happily||ai : fah@happily.ai	anaya@acme.com
      // ugBhsx0fZwHMHHs8HjUo-pimchanok@happily||ai : pimchanok@happily.ai	mauro@acme.com
      // ugBhsx0fZwHMHHs8HjUo-boss@happily||ai : boss@happily.ai	gianni@acme.com

      const emailMapping = {
        "tareef@happily.ai": {
          email: "harlee@acme.com",
          firstname: "Harlee",
          lastname: "ACME",
          display_name: "Harlee A",
        },
        "narges@happily.ai": {
          email: "ivan@acme.com",
          firstname: "Ivan",
          lastname: "ACME",
          display_name: "Ivan A",
        },
        "bank@happily.ai": {
          email: "jensen@acme.com",
          firstname: "Jensen",
          lastname: "ACME",
          display_name: "Jensen A",
        },
        "feen@happily.ai": {
          email: "kirsten@acme.com",
          firstname: "Kirsten",
          lastname: "ACME",
          display_name: "Kirsten A",
        },
        "fah@happily.ai": {
          email: "anaya@acme.com",
          firstname: "Anaya",
          lastname: "ACME",
          display_name: "Anaya A",
        },
        "pimchanok@happily.ai": {
          email: "mauro@acme.com",
          firstname: "Mauro",
          lastname: "ACME",
          display_name: "Mauro A",
        },
        "boss@happily.ai": {
          email: "gianni@acme.com",
          firstname: "Gianni",
          lastname: "ACME",
          display_name: "Gianni A",
        },
      };

      // change children for pagination
      const formChildren = [];
      for (const i in formDetails.children) {
        const child = formDetails.children[i];
        let email = child.split("-")[1].replaceAll("||", ".");
        if (Object.prototype.hasOwnProperty.call(emailMapping, email)) {
          const dataObject = _.get(emailMapping, email);
          if (dataObject) {
            // console.log(`change ${email} to ${dataObject.email}`);
            email = dataObject.email;
            formChildren.push(email);
          }
        }
      }
      formDetails.children = formChildren;

      // adjust data
      formDetails.email = email;
      formDetails.creator = email;
      formDetails.uuid = "assessment_template#" + email;
      formDetails.form_name = "[Sample] Hiring Customer Support Q1 2023";

      // @see look at cloud function (firebase) not update progress
      // const now = helpers.now();
      // formDetails.created_at = now;
      formDetails.is_sample_data = true;

      const docRef = await addDoc(
        collection(fs, "assessment_templates"),
        formDetails
      );
      const formId = docRef.id;

      for (const i in asssessmentsData) {
        const asssessmentData: any = _.omit(asssessmentsData[i], "_id");
        const email = asssessmentData.email;
        if (Object.prototype.hasOwnProperty.call(emailMapping, email)) {
          const dataObject = _.get(emailMapping, email);
          if (_.isObject(dataObject)) {
            _.merge(asssessmentData, dataObject);
          }
        }

        asssessmentData.company_name = user.companyName;
        asssessmentData.form_id = formId;
        asssessmentData.creator = email;
        asssessmentData.deleted = false;
        asssessmentData.is_sample_data = true;

        const _newId = formId + "-" + helpers.emailKey(asssessmentData.email);
        await setDoc(doc(fs, "assessments", _newId), asssessmentData);
      }

      await setDoc(
        doc(fs, "routes", newRouteId),
        {
          uuid: formId,
        },
        { merge: true }
      );

      // mark flag to save sample data
      update(ref(db, path), { routeId: newRouteId, hasSampleData: true });
    },
    async fixRotueDataAll({
      commit,
      dispatch,
      rootState,
    }: {
      commit: any;
      dispatch: any;
      rootState: any;
    }): Promise<any> {
      console.log("fixRotueDataAll()");
      commit("loading", true);

      const fs = getFirestore();
      let querySnapshot;
      let q;

      // Routes
      const routesMap: any = {};
      q = query(collection(fs, "routes"));
      querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const doc = querySnapshot.docs[i];
        routesMap[doc.id] = doc.data();
      }

      // All assessment templates
      q = query(
        collection(fs, "assessment_templates"),
        orderBy("created_at", "desc")
      );
      querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const _doc = querySnapshot.docs[i];
        const data = _doc.data();
        const routeId = data.routeId;
        if (!routeId) {
          console.log(
            "Not found routeId on " +
              _doc.id +
              ", created at: " +
              data.created_at
          );

          const uuid = _doc.id;
          const routsDetails = routeData;
          routsDetails.uuid = uuid;
          routsDetails.data.creator = data.email;
          routsDetails.data.email = data.email;

          await dispatch("routes/create", routsDetails, {
            root: true,
          });
          const newRouteId = rootState.routes.lastInsertId;

          await setDoc(
            doc(fs, "assessment_templates", uuid),
            { routeId: newRouteId },
            {
              merge: true,
            }
          );

          console.log(
            `Resolved routeId for ${_doc.id}, new routeId: ${newRouteId}`
          );
          continue;
        }

        // if (data.uuid == "assessment_template#feelhuhu@gmail.com") continue;
        // console.log(data, "data");
        // console.log(rootState, "rootState");

        if (!Object.prototype.hasOwnProperty.call(routesMap, data.routeId)) {
          console.log(`Invalid routeId on  ${_doc.id}, route: ${data.routeId}`);

          const uuid = _doc.id;
          const routsDetails = routeData;
          routsDetails.uuid = uuid;
          routsDetails.data.creator = data.email;
          routsDetails.data.email = data.email;

          console.log(routsDetails, "routsDetails");

          await dispatch("routes/create", routsDetails, {
            root: true,
          });
          const newRouteId = rootState.routes.lastInsertId;

          await setDoc(
            doc(fs, "assessment_templates", uuid),
            { routeId: newRouteId },
            {
              merge: true,
            }
          );

          console.log(
            `Resolved routeId for ${_doc.id}, new routeId: ${newRouteId}`
          );
          // */

          continue;
        }
      }

      commit("loading", false);
    },
  },
};
