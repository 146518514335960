
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    totalData: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pages: 1,
      dataPerPage: 10,
    };
  },
  mounted() {
    this.calculatePages();
  },
  watch: {
    totalData() {
      this.calculatePages();
    },
    dataPerPage() {
      this.calculatePages();
    },
  },
  methods: {
    calculatePages() {
      this.pages = Math.ceil(this.totalData / this.dataPerPage);
    },
    changePage(page: number) {
      // Emit an event to the parent component indicating the page change
      const firstData = page * this.dataPerPage - (this.dataPerPage - 1);
      const lastData =
        page * this.dataPerPage > this.totalData
          ? this.totalData
          : page * this.dataPerPage;
      this.$emit("page-change", {
        currentPage: page,
        dataIndex: [firstData, lastData],
      });
    },
    handleCommand(command: number) {
      this.dataPerPage = command;
      this.changePage(1);
    },
  },
});
